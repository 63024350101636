import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Card,
  Typography,
  Box,
  Button,
  Grid2,
  useTheme,
  Theme,
  useMediaQuery,
  CircularProgress,
  Tooltip,
  Modal,
  Stack,
  Tabs,
  Tab,
  // ThemeProvider,
} from "@mui/material";
import isString from "lodash/isString";

import isNumber from "lodash/isNumber";

import {
  Info as InfoIcon,
  ThumbDownOffAlt,
  ThumbUpOffAlt,
} from "@mui/icons-material";

import {
  ConciergeV3Props,
  GraphDataOptions,
  Package,
  PackageCardProps,
  PackageDetailsProps,
  PackageDetailsSection,
  ScheduleFooterProps,
} from "./types";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Root, classes } from "./styles";

import {
  SummaryContent,
  ScopeContent,
  PricingContent,
  BillImpactContent,
  EnergyImpactContent,
  // LoadCalculationContent,
  EquipmentContent,
  WhyPearlEdisonContent,
  TestimonialContent,
} from "./components/sections";
// import SchedulingDialog from "@library/components/SchedulingDialog"; // FYI: commenting out and relying on calendly integration for now
import api from "@library/api";
import { Job } from "@library/domain/job";
import { Quote } from "@library/domain/quote";
import ErrorToast from "@library/components/ErrorToast";
import { formatCurrency, formatNumber } from "@library/common";
import AdvisorProfile from "@library/components/AdvisorProfile";
import { OnboardingPayload } from "@library/domain/onboarding";
import SwiperCarousel from "@library/components/SwiperCarousel";
import { SettingsContext } from "@library/settings/provider";
import { Estimate } from "@library/domain/estimate";

const A2ZeroLogo = "/assets/rebates/a2zero.png";

export const DataContext = createContext<{
  jobId: string;
  job: Partial<Job>;
  quoteId: string;
  quote: Partial<Quote>;
  refresh?: () => Promise<void>;
  setQuote?: React.Dispatch<React.SetStateAction<Quote | null>>;
}>({
  jobId: "",
  job: {},
  quoteId: "",
  quote: {},
  refresh: undefined,
  setQuote: undefined,
});

const PackageCard: React.FC<PackageCardProps> = ({
  theme,
  package: pkg,
  onSelect,
}) => {
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("sm"));
  const baseLanguageLocation = "concierge.PackageDetails.PackageCard";
  const monthlyPayment = pkg?.polyvectorPayload?.monthlyPayment || 0;

  return (
    <Box position="relative">
      <Card
        className={
          pkg.isSelected
            ? `${classes.selectedPackageContainer} ${classes.packageContainer}`
            : classes.packageContainer
        }
      >
        <Box>
          {/* Header section with fixed height */}
          <Box
            sx={{
              height: isMobileOrTablet ? "auto" : 80,
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
            }}
          >
            <Typography
              variant="h4"
              color={pkg.isSelected ? "primary" : "inherit"}
            >
              {theme?.t(`tier.${pkg.tier}.title`)}
            </Typography>
            {pkg.isRecommended && (
              <Typography
                variant="subtitle2"
                sx={{
                  backgroundColor: `${theme.palette.primary.lighter} !important`,
                  color: `${theme.palette.primary.main} !important`,
                }}
                className={classes.recommendedPackageText}
              >
                {theme?.t(`${baseLanguageLocation}.recommendedText`)}
              </Typography>
            )}
          </Box>

          {/* Content section */}
          <Typography variant="body2" sx={{ mt: 1 }}>
            {theme?.t(`tier.${pkg.tier}.snippet`)}
          </Typography>
        </Box>

        <Box sx={{ mt: 3, width: "100%" }}>
          <Box className={classes.bottomBorderContainer}>
            <Typography className={classes.boldText}>
              {theme?.t(`${baseLanguageLocation}.pricingOptions.title`)}
            </Typography>
          </Box>
          <Box className={classes.bottomBorderContainer}>
            <Typography variant="body2">
              {theme?.t(`${baseLanguageLocation}.pricingOptions.singlePayment`)}
            </Typography>
            <Typography className={classes.lightWeightText}>
              {formatCurrency(pkg.polyvectorPayload?.afterEligibleRebates)}
            </Typography>
          </Box>
          <Typography variant="body2">
            {theme?.t(`${baseLanguageLocation}.pricingOptions.financeIntro`)}{" "}
            <strong>{formatCurrency(monthlyPayment)}/month</strong>{" "}
            {theme?.t(`${baseLanguageLocation}.pricingOptions.financeDetails`)}
          </Typography>
        </Box>

        <Box sx={{ mt: 2, width: "100%" }}>
          <Box className={classes.bottomBorderContainer}>
            <Typography className={classes.boldText}>
              {theme?.t(`${baseLanguageLocation}.impacts.annual.title`)}
            </Typography>
            <Typography className={classes.lightWeightText}>
              {isNumber(pkg?.polyvectorPayload?.billImpactEstimate) &&
              pkg.doubt ? (
                (
                  <>
                    {formatCurrency(
                      pkg?.polyvectorPayload?.billImpactEstimate -
                        pkg?.polyvectorPayload?.billImpactEstimate * pkg?.doubt,
                      { roundTo: 10, showNegative: false, arrow: true }
                    )}
                    -
                    {formatCurrency(
                      pkg?.polyvectorPayload?.billImpactEstimate +
                        pkg?.polyvectorPayload?.billImpactEstimate * pkg?.doubt,
                      { roundTo: 10, showNegative: false }
                    )}
                  </>
                ) || "No impact"
              ) : isNumber(pkg?.polyvectorPayload?.billImpactEstimate) ? (
                <>
                  {formatCurrency(pkg?.polyvectorPayload?.billImpactEstimate, {
                    roundTo: 10,
                    showNegative: false,
                  })}
                </>
              ) : (
                theme?.t(`${baseLanguageLocation}.impacts.annual.tbd`)
              )}
              <Tooltip title={pkg?.polyvectorPayload?.billImpactExplanation}>
                <InfoIcon className={classes.lightIcon} />
              </Tooltip>
            </Typography>
          </Box>
          <Box className={classes.bottomBorderContainer}>
            <Typography className={classes.boldText}>
              {theme?.t(`${baseLanguageLocation}.impacts.climate.title`)}
            </Typography>
            <Typography className={classes.lightWeightText}>
              {isNumber(pkg?.polyvectorPayload?.climateImpactEstimate)
                ? formatNumber(
                    pkg?.polyvectorPayload?.climateImpactEstimate as number,
                    {
                      arrow: true,
                      units: theme?.t(
                        `${baseLanguageLocation}.impacts.climate.units`
                      ),
                      showNegative: false,
                      round: true,
                    }
                  ) || "No impact"
                : theme?.t(`${baseLanguageLocation}.impacts.climate.tbd`)}
              <Tooltip title={pkg?.polyvectorPayload?.climateImpactExplanation}>
                <InfoIcon className={classes.lightIcon} />
              </Tooltip>
            </Typography>
          </Box>
        </Box>

        <Box className={classes.cardFooterContent}>
          {pkg.isSelected ? (
            <Typography
              className={`${classes.cardFooterViewingText} ${classes.lightWeightText}`}
            >
              {theme?.t(`${baseLanguageLocation}.footer.currentlyViewing`)}
            </Typography>
          ) : (
            <Button
              fullWidth
              variant="outlined"
              sx={{ mt: 2 }}
              onClick={onSelect}
              className={classes.cardFooterButton}
            >
              {theme?.t(`${baseLanguageLocation}.footer.learnMore`)}
            </Button>
          )}
        </Box>
      </Card>
      {pkg.isSelected && <div className={classes.selectedPackageArrow} />}
    </Box>
  );
};

const ScheduleFooter: React.FC<ScheduleFooterProps> = ({
  onScheduleAssessment,
  onScheduleConsultation,
  theme,
  job,
}) => {
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const MobileFooter = () => (
    <Box className={classes.mobileFooterContainer}>
      <Grid2 container spacing={2}>
        <Grid2 size={6}>
          <Box>
            {!isMobile ? (
              <Typography variant="h6" className={classes.mobileFooterTitle}>
                {theme.t(`concierge.ScheduleFooter.finalizeButtonDescription`)}
              </Typography>
            ) : null}
            <Button
              fullWidth
              color="secondary"
              variant="contained"
              className={classes.mobileFooterButton}
              onClick={onScheduleAssessment}
            >
              {theme.t(`concierge.ScheduleFooter.finalizeButtonLabel`)}
            </Button>
          </Box>
        </Grid2>

        <Grid2 size={6}>
          <Box>
            {!isMobile ? (
              <Typography variant="h6" className={classes.mobileFooterTitle}>
                {theme.t(
                  `concierge.ScheduleFooter.consultationButtonDescription`
                )}
              </Typography>
            ) : null}
            <Button
              fullWidth
              color="secondary"
              variant="contained"
              className={classes.mobileFooterButton}
              onClick={onScheduleConsultation}
            >
              {theme.t(`concierge.ScheduleFooter.consultationButtonLabel`)}
            </Button>
          </Box>
        </Grid2>
      </Grid2>
    </Box>
  );

  const DesktopFooter = ({ job }: { job: Job }) => (
    <Grid2 container spacing={4}>
      <Grid2 size={4}>
        <AdvisorProfile
          payload={
            {
              user: {
                formatted_address: `${job?.Home?.address1} ${job?.Home?.city} ${job?.Home?.state}`,
              },
            } as OnboardingPayload
          }
          theme={theme}
          advisor={job?.Advisor || {}}
        />
      </Grid2>
      <Grid2 size={4}>
        <Box style={{ width: "100%" }}>
          <Typography sx={{ mb: 1 }}>
            {theme.t(`concierge.ScheduleFooter.finalizeButtonDescription`)}
          </Typography>
          <Button
            fullWidth
            color="secondary"
            variant="contained"
            className={classes.footerButton}
            onClick={onScheduleAssessment}
          >
            {theme.t(`concierge.ScheduleFooter.finalizeButtonLabel`)}
          </Button>
        </Box>
      </Grid2>

      <Grid2 size={4}>
        <Box>
          <Typography sx={{ mb: 1 }}>
            {theme.t(`concierge.ScheduleFooter.consultationButtonDescription`)}
          </Typography>
          <Button
            fullWidth
            color="secondary"
            variant="contained"
            className={classes.footerButton}
            onClick={onScheduleConsultation}
          >
            {theme.t(`concierge.ScheduleFooter.consultationButtonLabel`)}
          </Button>
        </Box>
      </Grid2>
    </Grid2>
  );

  return (
    <Box className={classes.footerContainer}>
      {isMobileOrTablet ? <MobileFooter /> : <DesktopFooter job={job} />}
    </Box>
  );
};

const PackageDetails: React.FC<PackageDetailsProps> = ({
  selectedPackage,
  selectedSection,
  onSectionChange,
  graphData,
  graphDataOptions,
  onGraphOptionChange,
  theme,
}) => {
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));

  const [loadingInterest, setLoadingInterest] = useState<boolean | null>(null); // null, true (thumbs up), or false (thumbs down)

  const { job, setQuote } = useContext(DataContext);

  const titleRef = useRef<HTMLDivElement>(null);

  // Add a ref to track previous section
  const prevSectionRef = useRef<string | null>(null);

  const scrollToSection = () => {
    if (titleRef.current) {
      const offsetTop = titleRef.current.offsetTop;
      const offset = 100;
      window.scrollTo({
        top: offsetTop - offset,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    // Check if this is a genuine section change, not just the initial selection
    if (
      prevSectionRef.current !== null &&
      prevSectionRef.current !== selectedSection
    ) {
      scrollToSection();
    }

    // Update the previous section ref
    prevSectionRef.current = selectedSection;
  }, [selectedSection]);

  const getSections = (
    selectedPackage: Package,
    theme: Theme,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    graphData: any,
    graphDataOptions: GraphDataOptions,
    onGraphOptionChange: (options: Partial<GraphDataOptions>) => void
  ): PackageDetailsSection[] => {
    // Create base sections array
    const sections: PackageDetailsSection[] = [
      {
        label: "Summary",
        value: "summary",
        content: (
          <SummaryContent
            package={selectedPackage}
            theme={theme}
            job={job as Job}
          />
        ),
      },
      {
        label: "Scope of Work",
        value: "scope",
        content: (
          <ScopeContent
            package={selectedPackage}
            theme={theme}
            job={job as Job}
          />
        ),
      },
      {
        label: "Pricing, Rebates & Financing",
        value: "pricing",
        content: (
          <PricingContent
            package={selectedPackage}
            theme={theme}
            job={job as Job}
          />
        ),
      },
      {
        label: "Bill Impact",
        value: "bill",
        content: (
          <BillImpactContent
            theme={theme}
            graphData={graphData}
            graphDataOptions={graphDataOptions}
            onGraphOptionChange={onGraphOptionChange}
            job={job as Job}
          />
        ),
      },
      {
        label: "Energy Savings Impact",
        value: "energy",
        content: (
          <EnergyImpactContent
            theme={theme}
            graphData={graphData}
            graphDataOptions={graphDataOptions}
            onGraphOptionChange={onGraphOptionChange}
            job={job as Job}
          />
        ),
      },
    ];

    // Conditionally add Equipment section if job.status > 20 (Home Assessment or beyond)
    if (job?.status && job.status > 20) {
      sections.push({
        label: "Equipment & Tech Specs",
        value: "equipment",
        content: (
          <EquipmentContent
            package={selectedPackage}
            theme={theme}
            job={job as Job}
          />
        ),
      });
    }

    // Add remaining sections
    sections.push(
      {
        label: "Why Pearl Edison?",
        value: "whyPearl",
        content: (
          <WhyPearlEdisonContent
            package={selectedPackage}
            theme={theme}
            job={job as Job}
          />
        ),
      },
      {
        label: "Testimonials",
        value: "testimonials",
        content: (
          <TestimonialContent
            package={selectedPackage}
            theme={theme}
            job={job as Job}
          />
        ),
      }
    );

    return sections;
  };

  const sections = getSections(
    selectedPackage,
    theme,
    graphData,
    graphDataOptions,
    onGraphOptionChange
  );

  const activeSection =
    sections.find((section) => section.value === selectedSection) ||
    sections[0];

  const getInterested = useCallback(() => {
    let _isInterested = null;
    if (selectedPackage && selectedPackage.tier) {
      const userInput = job?.userInput as {
        [key: string]: { isInterested: boolean };
      };
      _isInterested = userInput?.[selectedPackage.tier]?.isInterested ?? null;
    }
    return _isInterested;
  }, [selectedPackage, job]);
  const [isInterested, setIsInterested] = useState(getInterested());

  useEffect(() => {
    setIsInterested(getInterested());
  }, [selectedPackage, getInterested]);

  const handleCustomerInterest = async (value: boolean) => {
    const tier = selectedPackage.tier;

    if (tier) {
      try {
        setLoadingInterest(value); // Set which button is loading
        const userInput = {
          [tier]: {
            isInterested: value,
          },
        };
        const response = await api.put({
          endpoint: `customer/job/${job.id}`,
          data: { userInput },
        });

        if (response.success && setQuote) {
          setIsInterested(value);
        }
      } catch (error) {
        console.error("Error updating customer interest:", error);
      } finally {
        setLoadingInterest(null); // Reset loading state
      }
    }
  };

  const InterestedComponent = () => (
    <Box className={classes.interestedContainer}>
      <Typography variant="body2">
        {isMobileOrTablet ? "Interested?" : "Interested in this package?"}
      </Typography>
      <Button
        variant={isInterested === true ? "contained" : "outlined"}
        className={classes.thumbUpButton}
        onClick={() => handleCustomerInterest(true)}
        disabled={isInterested === true || loadingInterest !== null}
        sx={{
          ml: 1,
          backgroundColor:
            isInterested === true ? theme.palette.info.main : "transparent",
          color:
            isInterested === true
              ? theme.palette.secondary.contrastText
              : theme.palette.info.main,
          borderColor: theme.palette.info.main,
          "&:hover": {
            backgroundColor:
              isInterested === true ? theme.palette.info.main : "transparent",
          },
          "&.Mui-disabled": {
            backgroundColor: theme.palette.info.main,
            color: theme.palette.secondary.contrastText,
            opacity: 0.7,
          },
        }}
      >
        {loadingInterest === true ? (
          <CircularProgress size={24} color="inherit" />
        ) : (
          <ThumbUpOffAlt />
        )}
      </Button>
      <Button
        variant={isInterested === false ? "contained" : "outlined"}
        className={classes.thumbDownButton}
        onClick={() => handleCustomerInterest(false)}
        disabled={isInterested === false || loadingInterest !== null}
        sx={{
          ml: 1,
          backgroundColor:
            isInterested === false ? theme.palette.error.main : "transparent",
          color:
            isInterested === false
              ? theme.palette.error.contrastText
              : theme.palette.error.main,
          borderColor: theme.palette.error.main,
          "&:hover": {
            backgroundColor:
              isInterested === false ? theme.palette.error.main : "transparent",
          },
          "&.Mui-disabled": {
            backgroundColor: theme.palette.error.main,
            color: theme.palette.error.contrastText,
            opacity: 0.7,
          },
        }}
      >
        {loadingInterest === false ? (
          <CircularProgress size={24} color="inherit" />
        ) : (
          <ThumbDownOffAlt />
        )}
      </Button>
    </Box>
  );

  const shouldShowDisclaimer = () => {
    // return selectedSection === "pricing";
    return true;
  };

  const excludedSections = ["whyPearl", "testimonials"]; // add more as needed, bud
  const shouldShowInterestedComponent = () => {
    return !excludedSections.includes(selectedSection);
  };

  return (
    <>
      <Box
        className={classes.packageDetailsContainer}
        sx={{ mb: shouldShowDisclaimer() ? 0 : 12 }}
      >
        {isMobileOrTablet ? (
          // Mobile Layout with Tabs
          <Stack spacing={2}>
            <Box className={classes.packageDetailsTitleContainer}>
              <Box
                sx={{ borderBottom: 1, borderColor: "divider", width: "100%" }}
              >
                <Tabs
                  value={selectedSection}
                  onChange={(_, newValue) => onSectionChange(newValue)}
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="section tabs"
                >
                  {sections.map((section) => (
                    <Tab
                      key={section.value}
                      label={section.label}
                      value={section.value}
                    />
                  ))}
                </Tabs>
              </Box>
            </Box>
            {shouldShowInterestedComponent() && (
              <Box className={classes.mobileInterestedContainer}>
                {<InterestedComponent />}
              </Box>
            )}
            {activeSection.content}
          </Stack>
        ) : (
          <Grid2 container spacing={2}>
            <Grid2
              size={{
                xs: 12,
                md: 9,
              }}
            >
              <Box>
                <Box className={classes.packageDetailsTitleOuterContainer}>
                  <Box className={classes.packageDetailsTitleContainer}>
                    <Typography className={classes.packageDetailsTitle}>
                      {theme?.t(`tier.${selectedPackage?.tier}.title`)}
                    </Typography>
                    <Typography
                      variant="h4"
                      ref={titleRef}
                      className={classes.packageDetailsSummary}
                    >
                      {activeSection.label} {/* scroll to this label */}
                    </Typography>
                  </Box>
                  {shouldShowInterestedComponent() && <InterestedComponent />}
                </Box>
                {activeSection.content}
              </Box>
            </Grid2>
            <Grid2
              size={{
                xs: 12,
                md: 3,
              }}
            >
              <Box className={classes.verticalNavContainer}>
                {sections.map((section) => (
                  <Button
                    key={section.value}
                    fullWidth
                    className={`${classes.verticalNavButton} ${
                      selectedSection === section.value
                        ? classes.verticalNavButtonActive
                        : ""
                    }`}
                    onClick={() => onSectionChange(section.value)}
                  >
                    {section.label}
                  </Button>
                ))}
              </Box>
            </Grid2>
          </Grid2>
        )}
      </Box>
      {shouldShowDisclaimer() && (
        <Box className={classes.disclaimerContainer}>
          <span>
            <Typography display="inline" className={classes.disclaimerTitle}>
              {theme?.t(
                `concierge.PackageDetails.PricingContent.disclaimer.title`
              )}
            </Typography>
            <Typography
              display="inline"
              className={classes.disclaimerDescription}
            >
              {theme?.t(
                `concierge.PackageDetails.PricingContent.disclaimer.description`
              )}
            </Typography>
          </span>
        </Box>
      )}
    </>
  );
};

const RebateModal = ({ job }: { job: Job | null }) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  const aggregateRebateTotals = (estimates: Estimate[]) => {
    const rebateTotals = {
      "Eligible Rebate": 0,
      "Potential Additional Rebate": 0,
      "Maximum Rebate": 0,
    };

    if (!estimates || estimates.length === 0) {
      return rebateTotals;
    }

    estimates.forEach((estimate) => {
      if (!estimate.polyvectorPayload?.rebates) {
        return;
      }

      const rebates = estimate.polyvectorPayload.rebates;

      // Aggregate totals across all rebates for this estimate
      let totalEligible = 0;
      const totalPotentiallyEligible =
        estimate.polyvectorPayload.potentiallyEligible || 0;

      rebates.forEach((rebate: { totals: { eligible: number } }) => {
        // Use the pre-calculated totals from each rebate
        totalEligible += rebate.totals.eligible || 0;
      });

      // Update the aggregated totals, taking the highest values found
      const maxTotal = totalEligible + totalPotentiallyEligible;

      if (maxTotal > rebateTotals["Maximum Rebate"]) {
        rebateTotals["Eligible Rebate"] = totalEligible;
        rebateTotals["Potential Additional Rebate"] = totalPotentiallyEligible;
        rebateTotals["Maximum Rebate"] = maxTotal;
      }
    });

    return rebateTotals;
  };

  useEffect(() => {
    if (job?.channelId !== "a2zero") return;

    const rebateTotals = aggregateRebateTotals(job?.Estimate);

    if (rebateTotals["Maximum Rebate"] > 0) {
      setOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          outline: "none", // To remove the focus outline
          backgroundColor: "white",
          padding: "32px",
          borderRadius: "8px",
          maxWidth: "600px",
          position: "relative",
        }}
      >
        <Grid2 container sx={{ width: "100%" }} spacing={1}>
          <Grid2 size={12}>
            <Typography align="center" variant="h5">
              Note from the City of Ann Arbor
            </Typography>
          </Grid2>
          <Grid2 size={12}>
            <Typography align="center">
              {theme.config.name} rebates might go quickly. To secure yours,
              schedule a free home assessment with a Pearl Edison advisor today.
            </Typography>
          </Grid2>
          <Grid2
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
            size={12}
          >
            <img src={A2ZeroLogo} width="200" />
          </Grid2>
        </Grid2>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            paddingTop: theme.spacing(1),
          }}
        >
          <Button variant="contained" onClick={handleClose} sx={{ px: 5 }}>
            View My Estimate
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export const ConciergeV3 = ({
  user,
  packages,
  selectedPackageIndex,
  isLoading,
  error,
  job,
  quote,
  onPackageSelect,
  onScheduleAssessment,
  onScheduleConsultation,
  setError,
  setQuote,
  refresh = async () => {},
}: ConciergeV3Props) => {
  const theme = useTheme();
  const { setChannel } = useContext(SettingsContext);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [graphData, setGraphData] = useState<any>(null);
  const [selectedSection, setSelectedSection] = useState("summary");
  const [graphDataOptions, setGraphDataOptions] = useState<GraphDataOptions>({
    weatherSeverity: "Average",
    energyUsage: "Status Quo",
    energyPrices: "Status Quo",
  });

  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));

  const fetchGraphData = async () => {
    try {
      const payload = {
        ...job?.Onboarding?.data,
        energyPrices: graphDataOptions.energyPrices,
        weatherSeverity: graphDataOptions.weatherSeverity,
      };

      const response = await api.post("customer/engine/partial", {
        payload: payload,
        fields: ["energy_model"],
        tier: packages?.[selectedPackageIndex].tier,
      });

      if (response.success) {
        setGraphData(response.data?.data?.energyModel || {});
      }
    } catch (error) {
      console.error("Error fetching graph data:", error);
    }
  };

  useEffect(() => {
    if (job) {
      fetchGraphData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [job, graphDataOptions, selectedPackageIndex]);

  useEffect(() => {
    setChannel(job?.channelId ?? "pearl");
  }, [job, setChannel]);

  const handleGraphOptionChange = (options: Partial<GraphDataOptions>) => {
    setGraphDataOptions((prev) => ({
      ...prev,
      ...options,
    }));
  };

  const handleSectionChange = (section: string) => {
    setSelectedSection(section);
  };

  let recommended = theme.config?.tiers?.recommended as string | string[];
  if (!recommended) {
    recommended = [];
  }
  if (recommended && isString(recommended)) {
    recommended = [recommended];
  }
  if (!recommended.length && packages.length) {
    packages[0].isRecommended = true;
  } else {
    for (let i = 0; i < recommended.length; i += 1) {
      const p = packages.find((p) => p.tier === recommended[i]);
      if (p) {
        p.isRecommended = true;
        break;
      }
    }
  }

  const sortedPackages = [...packages].sort((a, b) => {
    if (a.isRecommended && !b.isRecommended) return -1;
    if (!a.isRecommended && b.isRecommended) return 1;
    return 0;
  });

  return (
    <DataContext.Provider
      value={{
        jobId: job?.id || "",
        job: job || ({} as Partial<Job>),
        quote: quote || ({} as Partial<Quote>),
        quoteId: quote?.id || "",
        setQuote,
      }}
    >
      <Root>
        <Box className={classes.titleContainer}>
          <Typography variant="h4" align="center" className={classes.titleText}>
            {user?.firstName || "Hello"}
            {isLoading
              ? theme.t(`concierge.loadingTitle`)
              : theme.t(`concierge.title`)}
          </Typography>

          <Typography align="center" variant="body2">
            {theme.t(`concierge.description`)}
          </Typography>

          <ErrorToast onClose={() => setError("")} text={error} />
        </Box>

        {isLoading ? (
          <Box sx={{ width: "100%" }} className={classes.loadingContainer}>
            <CircularProgress />
          </Box>
        ) : (
          <Box className={classes.contentContainer}>
            {isMobileOrTablet && (
              <Box sx={{ mb: 2 }}>
                <AdvisorProfile
                  collapseOnMobile={false}
                  payload={
                    {
                      user: {
                        formatted_address: `${job?.Home?.address1} ${job?.Home?.city} ${job?.Home?.state}`,
                      },
                    } as OnboardingPayload
                  }
                  theme={theme}
                  advisor={job?.Advisor || {}}
                />
              </Box>
            )}

            <SwiperCarousel
              items={sortedPackages}
              renderItem={(pkg, index, isSelected) => (
                <PackageCard
                  theme={theme}
                  package={{
                    ...pkg,
                    isSelected,
                  }}
                  onSelect={() => onPackageSelect(index)}
                />
              )}
              selectedIndex={selectedPackageIndex}
              onSelectItem={onPackageSelect}
              subTitle={`${theme.t(`concierge.SwiperCarousel.title`)} ${job?.Home?.address1 || ""}:`}
              selectable={true}
            />

            <PackageDetails
              theme={theme}
              graphData={graphData}
              selectedPackage={sortedPackages[selectedPackageIndex] || {}}
              selectedSection={selectedSection}
              onSectionChange={handleSectionChange}
              graphDataOptions={graphDataOptions}
              onGraphOptionChange={handleGraphOptionChange}
              refresh={refresh}
            />
          </Box>
        )}

        <ScheduleFooter
          theme={theme}
          onScheduleAssessment={onScheduleAssessment}
          onScheduleConsultation={onScheduleConsultation}
          job={job as Job}
        />

        <RebateModal job={job} />
      </Root>
    </DataContext.Provider>
  );
};

export default ConciergeV3;
