import React, { useCallback } from "react";
import { Box, SxProps, Theme, Typography } from "@mui/material";
import { useDropzone, DropzoneOptions } from "react-dropzone";
import { Root, classes } from "./styles";
import { UploadFile } from "@mui/icons-material";

interface DropzoneAreaProps {
  sx?: SxProps<Theme>;
  onFilesAdded: (acceptedFiles: File[]) => void;
}

function DropzoneArea({ sx, onFilesAdded }: DropzoneAreaProps) {
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      onFilesAdded(acceptedFiles);
    },
    [onFilesAdded]
  );

  const dropzoneOptions: DropzoneOptions = {
    onDrop,
    accept: {
      "image/*": [".jpeg", ".png", ".jpg", ".gif"],
      "application/pdf": [".pdf"],
    },
  };

  const { getRootProps, getInputProps } = useDropzone(dropzoneOptions);

  return (
    <Root sx={sx}>
      <Box {...getRootProps()} className={classes.dropzoneContainer}>
        <input {...getInputProps()} id="dropzone-area" />
        <UploadFile />
        <Typography sx={{ mt: 2 }}>
          <span className={classes.ctaText}>Click to upload</span> or drag and
          drop
        </Typography>
        <Typography variant="body2" sx={{ mt: 2 }}>
          SVG, PNG, JPG, GIF, or PDF
        </Typography>
      </Box>
    </Root>
  );
}

export default DropzoneArea;
