import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  useTheme,
  Dialog,
  Box,
  Grid2,
  CircularProgress,
  ThemeProvider,
  Alert,
} from "@mui/material";
import { SettingsContext } from "@library/settings/provider";
import { InlineWidget, useCalendlyEventListener } from "react-calendly";
import { Navigate, useParams } from "react-router-dom";
import api from "@library/api";
import { Job } from "@library/domain/job";
import { Quote } from "@library/domain/quote";
import {
  CALENDLY_URL_HOME_ASSESSMENT,
  CALENDLY_URL_VIRTUAL_ASSESSMENT,
  EMAIL,
  PHONE_NUMBER,
} from "@library/common";
import { Estimate } from "@library/domain/estimate";
import { TenantName } from "@library/theme/multitenancy";
import { castEstimates } from "@library/api/models/PolyvectorPayloadModel";
import ConciergeV3 from "@library/components/ConciergeV3";
import { getTheme } from "@library/theme/createDefaultTheme";
import InformationDialog from "@library/components/InformationDialog";

const ConciergePageViewV3 = () => {
  const { jobId, quoteId = "" } = useParams();
  const {
    user,
    setChannel,
    isAuthenticated,
    isLoading: _isLoading,
  } = useContext(SettingsContext);
  const [selectedPackageIndex, setSelectedPackageIndex] = useState(0); // Default to Pearl Package
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");
  const [quote, setQuote] = useState<Quote | null>(null);
  const [job, setJob] = useState<Job | null>(null);
  const [isScheduleAssessmentDialogOpen, setIsScheduleAssessmentDialogOpen] =
    useState(false);
  const [
    isScheduleConsultationDialogOpen,
    setisScheduleConsultationDialogOpen,
  ] = useState(false);
  const [isScheduleSuccessDialogOpen, setIsScheduleSuccessDialogOpen] =
    useState(false);
  const [successDialogTitle, setSuccessDialogTitle] = useState("Scheduled!");

  useCalendlyEventListener({
    onEventScheduled: () => {
      setIsScheduleAssessmentDialogOpen(false);
      setisScheduleConsultationDialogOpen(false);
      setIsScheduleSuccessDialogOpen(true);
    },
  });

  const theme = useTheme();
  const tiers = theme.config?.tiers?.available ?? ["base", "replace", "pearl"];
  const defaultTier = theme.config?.tiers?.default || "pearl";

  const packages =
    job?.Estimate?.filter((e) => e.viewable).sort(
      (a, b) => tiers.indexOf(a.tier) - tiers.indexOf(b.tier)
    ) || [];

  const fetchConciergeData = async () => {
    if (!isAuthenticated) return true;
    try {
      const response = await api.get({
        endpoint: "customer/concierge",
        params: {
          jobId,
          quoteId: quoteId,
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        cast: (data: any) => {
          if (data?.data?.job?.Estimate?.length) {
            const _estimates = data.data.job.Estimate.map((e: Estimate) => {
              return {
                ...e,
                FinancingPartner: data.data.job.FinancingPartner || [],
              };
            });
            data.data.job.Estimate = castEstimates(_estimates, theme);
          }
          return data;
        },
      });
      if (response.success) {
        const _job = response.data.job;
        setJob(_job);
        setQuote(response.data.quote);
        const _packages =
          _job?.Estimate?.filter((e: Estimate) => e.viewable).sort(
            (a: Estimate, b: Estimate) =>
              tiers.indexOf(a.tier) - tiers.indexOf(b.tier)
          ) || [];
        let defaultSelectedPackageIndex = 0;
        if (_job?.userInput?.selectedPackageIndex >= 0) {
          defaultSelectedPackageIndex = _job?.userInput?.selectedPackageIndex;
        } else {
          defaultSelectedPackageIndex = _packages.findIndex(
            (e: Estimate) => e.tier === defaultTier
          );
        }
        if (
          defaultSelectedPackageIndex > _job?.Estimate?.length - 1 ||
          defaultSelectedPackageIndex < 0
        ) {
          defaultSelectedPackageIndex = 0;
        }
        setSelectedPackageIndex(defaultSelectedPackageIndex);
      } else {
        setError("Failed to fetch jobs");
      }
      setIsLoading(false);
    } catch (e) {
      console.error("Error fetching jobs:", e);
      setError("An error occurred while fetching jobs");
      setIsLoading(false);
    }
  };

  const refresh = useCallback(async (_setIsLoading = true) => {
    setIsLoading(_setIsLoading);
    setError("");
    await fetchConciergeData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Use refresh for initial data fetch
  useEffect(() => {
    refresh();
    if (!isLoading && isAuthenticated) {
      setIsLoading(true);
      setTimeout(() => {
        refresh();
      }, 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (job?.channelId) {
      setChannel(job.channelId as TenantName);
    }
  }, [job, setChannel]);

  const handlePackageSelect = (index: number) => {
    setSelectedPackageIndex(index);
    if (job?.id) {
      api
        .put({
          endpoint: `customer/job/${job?.id}`,
          data: {
            userInput: {
              selectedPackageIndex: index,
            },
          },
        })
        .then(() => {
          refresh(false);
        });
    }
  };

  const toggleScheduleAssessmentDialog = () => {
    setSuccessDialogTitle("Home Assessment Scheduled!");
    setIsScheduleAssessmentDialogOpen((prev) => !prev);
  };

  const toggleScheduleConsultationDialog = () => {
    setSuccessDialogTitle("Call Scheduled!");
    setisScheduleConsultationDialogOpen((prev) => !prev);
  };

  const toggleScheduleSuccessDialog = () => {
    setIsScheduleSuccessDialogOpen((prev) => !prev);
  };

  const { channelTheme, channelData, language } = useContext(SettingsContext);
  const _theme = getTheme(channelTheme as TenantName, channelData, language);

  return (
    <>
      <ThemeProvider theme={_theme}>
        {!_isLoading && !isAuthenticated ? <Navigate to="/login" /> : null}
        {_isLoading || isLoading ? (
          <Box component={"main"}>
            <Grid2
              container
              direction="column"
              alignItems="center"
              justifyContent="center"
              sx={{
                width: "100%",
                position: "absolute",
                top: "-20px",
                bottom: 0,
                zIndex: 9999999999,
                left: 0,
                right: 0,
                backgroundColor: theme.palette.common.white,
              }}
            >
              <CircularProgress />
            </Grid2>
          </Box>
        ) : packages?.length > 0 ? (
          <ConciergeV3
            user={user}
            packages={packages}
            selectedPackageIndex={selectedPackageIndex}
            isLoading={isLoading}
            error={error}
            job={job}
            quote={quote}
            onPackageSelect={handlePackageSelect}
            onScheduleAssessment={toggleScheduleAssessmentDialog}
            onScheduleConsultation={toggleScheduleConsultationDialog}
            setError={setError}
            setQuote={setQuote}
            refresh={refresh}
          />
        ) : (
          <Grid2
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{ minHeight: "calc(100vh - 128px)" }}
            p={5}
          >
            <Grid2 size={12}>
              <Alert severity="error">
                {" "}
                We are unable to complete this request. Please contact us via
                the chat widget below, by email at {EMAIL} or by phone at{" "}
                {PHONE_NUMBER}.
              </Alert>
            </Grid2>
          </Grid2>
        )}

        {/* Scheduling dialogs */}
        <Dialog
          fullWidth
          maxWidth="lg"
          open={isScheduleAssessmentDialogOpen}
          onClose={toggleScheduleAssessmentDialog}
        >
          <InlineWidget
            url={
              job?.Advisor?.data?.calendars?.homeAssessment?.url ||
              CALENDLY_URL_HOME_ASSESSMENT
            }
            prefill={{
              email: job?.Onboarding?.data?.user?.email,
              firstName: job?.Onboarding?.data?.user?.firstName,
              lastName: job?.Onboarding?.data?.user?.lastName,
              name: `${job?.Onboarding?.data?.user?.firstName} ${job?.Onboarding?.data?.user?.lastName}`,
            }}
            pageSettings={{
              backgroundColor: "ffffff",
              hideEventTypeDetails: true,
              hideLandingPageDetails: true,
              primaryColor: theme.palette.primary.main,
              textColor: theme.palette.common.black,
            }}
          />
        </Dialog>
        <Dialog
          fullWidth
          maxWidth="lg"
          open={isScheduleConsultationDialogOpen}
          onClose={toggleScheduleConsultationDialog}
        >
          <InlineWidget
            url={
              job?.Advisor?.data?.calendars?.virtualAssessment?.url ||
              CALENDLY_URL_VIRTUAL_ASSESSMENT
            }
            prefill={{
              email: job?.Onboarding?.data?.user?.email,
              firstName: job?.Onboarding?.data?.user?.firstName,
              lastName: job?.Onboarding?.data?.user?.lastName,
              name: `${job?.Onboarding?.data?.user?.firstName} ${job?.Onboarding?.data?.user?.lastName}`,
            }}
            pageSettings={{
              backgroundColor: "ffffff",
              hideEventTypeDetails: true,
              hideLandingPageDetails: true,
              primaryColor: theme.palette.primary.main,
              textColor: theme.palette.common.black,
            }}
          />
        </Dialog>
        <InformationDialog
          title={successDialogTitle}
          isOpen={isScheduleSuccessDialogOpen}
          onClose={toggleScheduleSuccessDialog}
          buttonText="Got It"
          text="Check your inbox for an email with more details."
        />
      </ThemeProvider>
    </>
  );
};

export default ConciergePageViewV3;
