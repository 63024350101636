/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { useEffect, useState } from "react";
import DropzoneArea from "@library/components/DropzoneArea";
import { Grid2, useTheme } from "@mui/material";
import FileRow from "@library/components/FileRow";
import api from "@library/api";
import ConfirmationDialog from "@library/components/ConfirmationDialog";

export const AssessmentFloorplans = ({ slideState }: { slideState: any }) => {
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState(false);
  const [floorplanFiles, setFloorplanFiles] = useState<
    Array<{ id?: string; name: string; size?: string; url?: string }>
  >([]);
  const [fileToDelete, setFileToDelete] = useState<{ index: number } | null>(
    null
  );
  const [fetchedImageIds, setFetchedImageIds] = useState<string[]>([]);

  const theme = useTheme();

  const job = slideState.job;

  // useEffect to fetch and display existing floorplan images
  useEffect(() => {
    if (!job.Image || job.Image.length === 0) return;

    const promises = [];
    const newImages = job.Image.filter(
      (image: any) =>
        image.type === "floorplan" && !fetchedImageIds.includes(image.id)
    );

    for (const image of newImages) {
      promises.push(api.get("customer/image", { id: image.id }));
    }

    Promise.all(promises).then((responses) => {
      if (responses?.length) {
        const updatedFloorplanFiles = [...floorplanFiles];

        responses.forEach((response) => {
          const imageData = response.data;

          // Check if image doesn't already exist in our state
          if (!floorplanFiles.some((file) => file.id === imageData.id)) {
            updatedFloorplanFiles.push({
              name: imageData.title,
              id: imageData.id,
              url: imageData.sizes?.small ?? imageData.sizes?.original,
              size: imageData.size
                ? `${(imageData.size / 1024).toFixed(0)}kb`
                : undefined,
            });
          }
        });

        setFetchedImageIds((prev) => [
          ...prev,
          ...newImages.map((img: any) => img.id),
        ]);
        setFloorplanFiles(updatedFloorplanFiles);
      }
    });
  }, [job, fetchedImageIds, floorplanFiles]);

  const handleFileUpload = async (acceptedFiles: File[], title?: string) => {
    const newFiles = acceptedFiles.map((file) => ({
      file,
      name: file.name,
      size: `${(file.size / 1024).toFixed(0)}kb`,
      id: "",
    }));

    for (const file of newFiles) {
      const form = new FormData();
      form.append("title", title || `Floorplan: ${file.name}`);
      form.append("type", "floorplan");
      form.append("jobId", job.id ?? "");
      form.append(file.name, file.file);

      try {
        const response = await api.post(
          "customer/image",
          form,
          {},
          { "Content-Type": "multipart/form-data" }
        );

        file.id = response.data.images[0].id;
      } catch (error) {
        console.error(`Failed to upload floorplan image:`, error);
        // TODO: handle error (e.g., show an error message to the user)
      }
    }

    return newFiles;
  };

  const handleFloorplanFilesAdded = async (acceptedFiles: File[]) => {
    const newFiles = await handleFileUpload(acceptedFiles, "Floorplan Image");
    setFloorplanFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const handleReupload = async (index: number, newFile: File) => {
    const form = new FormData();
    form.append("title", newFile.name);
    form.append("type", "floorplan");
    form.append("jobId", job.id ?? "");
    form.append(newFile.name, newFile);

    try {
      const response = await api.post(
        "customer/image",
        form,
        {},
        { "Content-Type": "multipart/form-data" }
      );

      const updatedFile = {
        file: newFile,
        name: newFile.name,
        size: `${(newFile.size / 1024).toFixed(0)}kb`,
        id: response.data.id,
        url: URL.createObjectURL(newFile),
      };

      setFloorplanFiles((prevFiles) => {
        const newFiles = [...prevFiles];
        newFiles[index] = updatedFile;
        return newFiles;
      });
    } catch (error) {
      console.error("Failed to reupload home assessment file:", error);
      // TODO: handle error (e.g., show an error message to the user)
    }
  };

  const handleDeleteFile = (index: number) => {
    setFileToDelete({ index });
    setIsDeleteConfirmationOpen(true);
  };

  const handleConfirmDelete = async () => {
    if (!fileToDelete) return;

    const { index } = fileToDelete;

    const fileId = floorplanFiles[index].id;

    setFloorplanFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));

    if (fileId) {
      try {
        await api.delete(`customer/image/${fileId}`);
      } catch (error) {
        console.error("Failed to delete file from server:", error);
        // TODO: handle error (e.g., show an error message to the user)
      }
    }

    setIsDeleteConfirmationOpen(false);
    setFileToDelete(null);
  };

  return (
    <Grid2 container>
      <Grid2 size={12}>
        <DropzoneArea onFilesAdded={handleFloorplanFilesAdded} />
      </Grid2>
      <Grid2 size={12}>
        {floorplanFiles.map((file: any, index: number) => (
          <FileRow
            key={index}
            fileUrl={file.url}
            fileName={file.name}
            fileSize={file.size || ""}
            onReupload={(newFile) => handleReupload(index, newFile)}
            onDelete={() => handleDeleteFile(index)}
          />
        ))}
      </Grid2>
      <ConfirmationDialog
        isOpen={isDeleteConfirmationOpen}
        onClose={() => setIsDeleteConfirmationOpen(false)}
        handleConfirm={handleConfirmDelete}
        backgroundColor={theme.palette.error.main}
        text="Are you sure you want to remove this asset?"
      />
    </Grid2>
  );
};

export default AssessmentFloorplans;
