import React, { ReactNode, useRef } from "react";
import without from "lodash/without";
import uniq from "lodash/uniq";
import { WidgetProps } from "@rjsf/utils";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid2,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";

import assets from "../assets";

const RadioCard = ({
  name,
  value,
  image,
  borderRadius = 10,
}: {
  name: string;
  value: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  image?: React.FC<{ styles: any }>;
  borderRadius: number;
}) => {
  const theme = useTheme();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const Image = image as React.FC<{ styles: any }>;
  return (
    <>
      <Stack alignItems="center" sx={{ borderRadius }}>
        <Image styles={{ borderRadius, width: "100%", height: "auto" }} />
      </Stack>
      <Typography sx={{ marginTop: "5px", width: "100%" }}>
        {theme.t(`labels.${name}.enum.${value}`) || value}
      </Typography>
    </>
  );
};

const Empty = () => {
  return <></>;
};

const Component = ({
  checked,
  noImage,
  props,
  value,
  name,
  borderRadius,
  image,
  fontSize,
  optionLabel,
}: {
  checked: boolean;
  color: string;
  RadioLabel: ReactNode;
  noImage: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setPayload: (val: any) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
  name: string;
  image?: React.FC;
  borderRadius?: string | number;
  fontSize?: string | number;
  optionLabel?: string;
}) => {
  const theme = useTheme();
  const ref = useRef<HTMLInputElement>(null);
  const Image = image as React.FC<{ styles: object }>;

  return (
    <Box
      onClick={(event: React.MouseEvent<HTMLElement>) => {
        const _ref = ref.current;
        if (_ref?.contains(event.target as Node)) return;
        _ref?.click();
      }}
      sx={{
        cursor: "pointer",
        backgroundColor: theme.palette.primary.contrastText,
        borderRadius: 1,
        padding: 2,
        paddingBottom: noImage ? 2.75 : 2,
        height: "100%",
      }}
    >
      <FormControlLabel
        ref={ref}
        value={value}
        control={
          <Stack justifyContent="center" alignItems="flex-start" width="100%">
            <Stack alignItems="center" sx={{ borderRadius }}>
              <Image styles={{ borderRadius, width: "100%", height: "auto" }} />
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-start"
            >
              <Stack sx={{ mr: 1 }}>
                <Checkbox
                  value={value}
                  disableRipple
                  onChange={(event) => {
                    let newValue = props.value;
                    if (event.target.checked) {
                      newValue = uniq([...props.value, event.target.value]);
                    } else {
                      newValue = without(props.value, event.target.value);
                    }
                    props.onChange(newValue);
                    // setPayload({
                    //   ...payload,
                    //   [props.name]: newValue,
                    // });
                  }}
                  checked={checked}
                  sx={{
                    opacity: "1.0",
                    margin: "0px",
                    padding: "0px",
                    pt: 1,
                    // position: noImage ? "inherit" : "absolute",
                    // top: noImage ? "3px" : "5px",
                    // left: noImage ? "inherit" : "5px",
                    paddingRight: noImage ? "10px" : "inherit",
                    color: checked ? theme.palette.primary.main : undefined,
                    svg: {
                      fill: checked ? theme.palette.primary.main : undefined,
                    },
                    "> span": {
                      color: checked ? theme.palette.primary.main : undefined,
                    },
                  }}
                />
              </Stack>
              <Stack>
                <Typography
                  sx={{
                    fontWeight: "medium",
                    paddingTop: noImage ? "12px" : "7px",
                    width: "100%",
                    fontSize,
                  }}
                >
                  {theme.t(`labels.${name}.enum.${value}.title`) ||
                    theme.t(`labels.${name}.enum.${value}`) ||
                    optionLabel ||
                    value}
                </Typography>
                {theme.t(`labels.${name}.enum.${value}.description`) ? (
                  <Typography
                    sx={{
                      fontSize: "12px",
                    }}
                  >
                    {theme.t(`labels.${name}.enum.${value}.description`)}
                  </Typography>
                ) : null}
              </Stack>
            </Stack>
          </Stack>
        }
        label={<></>}
        sx={{
          margin: "0px",
          position: "relative",
          width: "100%",
        }}
      />
    </Box>
  );
};

export const CheckboxImages = (props: WidgetProps) => {
  const theme = useTheme();
  const name = props.name as keyof typeof assets;
  const {
    inverted = false,
    width = ["100%"],
    xs = 6,
    sm = 4,
    md = 4,
    lg = 4,
    borderRadius = 10,
    fontSize,
    optionLabel,
  } = props.uiSchema ?? {};

  // Get child-specific grid sizes if they exist
  const {
    xs: childrenXS,
    sm: childrenSM,
    md: childrenMD,
    lg: childrenLG,
  } = props.uiSchema?.children ?? {};
  const { payload, setPayload } = props.formContext ?? {};
  return (
    <FormControl sx={{ alignItems: "center" }}>
      <Grid2
        container
        rowSpacing={0}
        columnSpacing={0}
        direction="row"
        alignItems="stretch"
        justifyContent="center"
        gap={0}
        sx={{
          width,
          px: 1,
        }}
      >
        {props.schema?.items?.enum?.map((option: string) => {
          const value = option as keyof (typeof assets)[typeof name];
          let Image = assets?.[name]?.[value] as React.FC | undefined;
          let noImage = false;
          if (!Image) {
            Image = Empty;
            noImage = true;
          }
          const checked = props.value.includes(value);
          const RadioLabel = (
            <RadioCard
              name={name}
              value={value}
              image={Image}
              borderRadius={borderRadius}
            />
          );
          const color =
            noImage && !checked
              ? theme.palette.primary.main
              : inverted
                ? theme.palette.primary.main
                : theme.palette.primary.contrastText;
          return (
            <Grid2
              key={value}
              p={0.2}
              size={{
                xs: childrenXS ?? xs,
                sm: childrenSM ?? sm,
                md: childrenMD ?? md,
                lg: childrenLG ?? lg,
              }}
            >
              <Component
                checked={checked}
                color={color}
                RadioLabel={RadioLabel}
                payload={payload}
                setPayload={setPayload}
                props={props}
                value={value}
                noImage={noImage}
                name={name}
                image={Image}
                borderRadius={borderRadius}
                fontSize={fontSize}
                optionLabel={optionLabel}
              />
            </Grid2>
          );
        })}
      </Grid2>
    </FormControl>
  );
};

export default CheckboxImages;
