import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Grid2,
  IconButton,
  useTheme,
  Button,
  Typography,
} from "@mui/material";
import * as d3 from "d3";
import UndoIcon from "@mui/icons-material/Undo";

import CardWithHeader from "../CardWithHeader";
import { Root, classes } from "./styles";
import { Point } from "./types";
import api from "@library/api";
import { Annotation, AnnotationLink } from "@library/domain/image";
import ImageUploadWithPlaceholder from "../ImageUploadWithPlaceholder";
import { camelCaseToHuman } from "@library/common";
import { Job } from "@library/domain/job";

interface Room {
  id: string;
  name: string;
}

interface Line {
  start: Point;
  end: Point;
  floor: string;
}

interface ImageMarkerProps {
  job?: Job;
  floorplans: {
    floor: string;
    imageFile?: File;
    url?: string;
    imageId: string;
  }[];
  rooms: Room[];
  type: string;
  annotations: Annotation[];
  annotationLinks: AnnotationLink[];
  disabled?: boolean;
  height?: string | number;
}

const FONT_SIZE = 30;
const POINT_SIZE = 50;

const ImageMarker: React.FC<ImageMarkerProps> = ({
  type,
  floorplans = [],
  annotations,
  annotationLinks,
  disabled = false,
  height = "100%",
  job,
}) => {
  const [currentFloor, setCurrentFloor] = useState("first");
  const [currentFloorplan, setCurrentFloorplan] = useState<
    (typeof floorplans)[0] | null
  >(null);
  const [pointImages, setPointImages] = useState<Record<string, string>>({});
  const [allPoints, setAllPoints] = useState<Point[]>([]);
  const [localAnnotationLinks, setLocalAnnotationLinks] = useState<
    AnnotationLink[]
  >([]);
  const [imageSrc, setImageSrc] = useState<string | null>(null);
  const [linesByFloor, setLinesByFloor] = useState<Record<string, Line[]>>({});
  const [lineStart, setLineStart] = useState<Point | null>(null);
  const [transform, setTransform] = useState<d3.ZoomTransform>(d3.zoomIdentity);
  const [isAddingPoint, setIsAddingPoint] = useState(false);

  // refs
  const svgRef = useRef<SVGSVGElement>(null);
  const imageRef = useRef<SVGImageElement>(null);
  const lineStartRef = useRef<Point | null>(null);

  const theme = useTheme();

  const setCurrentFloorAndPlan = (floor: string) => {
    setCurrentFloor(floor);
    const newFloorplan = floorplans.find((fp) => fp.floor === floor);
    if (newFloorplan) {
      setCurrentFloorplan(newFloorplan);
    }
  };

  useEffect(() => {
    if (floorplans[0]?.floor) {
      setCurrentFloor(floorplans[0].floor);
      setCurrentFloorplan(floorplans[0]);
    }
  }, [floorplans]);

  const fetchAnnotationImage = async (pointId: string, imageId: string) => {
    try {
      const response = await api.get(`customer/image/${imageId}`);
      if (response.success && response.data?.sizes?.default) {
        return { pointId, url: response.data.sizes.default };
      }
    } catch (error) {
      console.error(`Error fetching image for annotation ${pointId}:`, error);
    }
    return null;
  };

  useEffect(() => {
    const setupAnnotationsAndImages = async () => {
      if (!currentFloorplan) return;

      const filteredAnnotations = annotations.filter(
        (annotation) => annotation.imageId === currentFloorplan.imageId
      );

      const newPoints = filteredAnnotations.map(
        (annotation, index): Point => ({
          id: annotation.id,
          x: annotation.x,
          y: annotation.y,
          type: annotation.type,
          index: index + 1,
          annotationImageId: annotation.annotationImageId,
        })
      );

      setAllPoints(newPoints);

      const filteredAnnotationLinks = annotationLinks.filter(
        (link) =>
          newPoints.some((point) => point.id === link.sourceAnnotationId) &&
          newPoints.some((point) => point.id === link.targetAnnotationId)
      );

      setLocalAnnotationLinks(filteredAnnotationLinks);

      // Fetch images for annotations that have an annotationImageId
      const imagePromises = newPoints
        .filter((point) => point.annotationImageId)
        .map((point) =>
          fetchAnnotationImage(point.id, point.annotationImageId!)
        );

      const images = await Promise.all(imagePromises);
      const newPointImages: Record<string, string> = {};
      images.forEach((image) => {
        if (image) {
          newPointImages[image.pointId] = image.url;
        }
      });

      setPointImages(newPointImages);
    };

    setupAnnotationsAndImages();
  }, [annotations, annotationLinks, currentFloorplan]);

  useEffect(() => {
    const currentFloorplan = floorplans.find((fp) => fp.floor === currentFloor);
    if (currentFloorplan) {
      if (currentFloorplan.imageFile) {
        const reader = new FileReader();
        reader.onload = (e) => {
          setImageSrc(e.target?.result as string);
        };
        reader.readAsDataURL(currentFloorplan.imageFile);
      } else if (currentFloorplan.url) {
        setImageSrc(currentFloorplan.url);
      }
    }
  }, [currentFloor, floorplans]);

  useEffect(() => {
    if (svgRef.current && imageRef.current && imageSrc) {
      const svg = d3.select(svgRef.current);
      const image = d3.select(imageRef.current);

      const zoom = d3
        .zoom<SVGSVGElement, unknown>()
        .scaleExtent([0.5, 8])
        .on("zoom", (event: d3.D3ZoomEvent<SVGSVGElement, unknown>) => {
          const newTransform = event.transform;
          setTransform(newTransform);
          image.attr("transform", newTransform.toString());
        });

      svg.call(zoom);

      // Set initial image size without zooming
      const img = new Image();
      img.src = imageSrc;
      img.onload = () => {
        const { width, height } = img;
        svg.attr("viewBox", `0 0 ${width} ${height}`);
        image.attr("width", width).attr("height", height);

        // Set initial transform to identity (no zoom, no translation)
        const initialTransform = d3.zoomIdentity;
        svg.call(zoom.transform, initialTransform);
      };

      return () => {
        svg.on(".zoom", null);
      };
    }
  }, [imageSrc, disabled]);

  const drawPointsAndLines = (
    currentTransform: d3.ZoomTransform,
    _points?: Point[],
    links?: AnnotationLink[],
    lines?: Record<string, Line[]>
  ) => {
    // Exit if there's no SVG element to draw on
    if (!svgRef.current) return;

    // Select the SVG element
    const svg = d3.select(svgRef.current);

    // Clear existing lines and points to redraw them
    svg.selectAll(".lines-group, .points-group").remove();

    // Create a new group for lines
    const linesGroup = svg.append("g").attr("class", "lines-group");

    // Draw lines based on annotationLinks
    const linkLines =
      ((links || localAnnotationLinks)
        ?.map((link) => {
          const sourcePoint = allPoints.find(
            (p) => p.id === link.sourceAnnotationId
          );
          const targetPoint = allPoints.find(
            (p) => p.id === link.targetAnnotationId
          );
          if (sourcePoint && targetPoint) {
            return {
              start: sourcePoint,
              end: targetPoint,
              floor: currentFloor, // Assuming all points on the same floor
            };
          }
          return null;
        })
        .filter((line) => line !== null) as Line[]) || [];

    // Add these lines to the current floor's lines
    const currentFloorLines = [
      ...((lines && lines[currentFloor]) || linesByFloor[currentFloor] || []),
      ...linkLines,
    ];

    // Select all line elements and bind them to the current floor's line data
    const lineSelection = linesGroup
      .selectAll<SVGLineElement, Line>(".line")
      .data(currentFloorLines, (d: Line) => `${d.start.id}-${d.end.id}`);

    // Remove lines that are no longer in the data
    lineSelection.exit().remove();

    // Add new lines and update existing ones
    lineSelection
      .enter()
      .append("line")
      .attr("class", "line")
      .merge(lineSelection)
      // Set line coordinates, applying the current zoom transform
      .attr("x1", (d) => currentTransform.applyX(d.start.x))
      .attr("y1", (d) => currentTransform.applyY(d.start.y))
      .attr("x2", (d) => currentTransform.applyX(d.end.x))
      .attr("y2", (d) => currentTransform.applyY(d.end.y))
      .attr("stroke", "blue")
      .attr("stroke-width", 10);

    // Create a new group for points
    const pointsGroup = svg.append("g").attr("class", "points-group");

    // Select all point elements and bind them to the current floor's point data
    const pointSelection = pointsGroup
      .selectAll<SVGGElement, Point>(".point")
      .data(allPoints, (d: Point) => d.id.toString());

    // Remove points that are no longer in the data
    pointSelection.exit().remove();

    // Add new points
    const pointEnter = pointSelection
      .enter()
      .append("g")
      .attr("class", "point")
      .style("cursor", "pointer")
      .on("click", (event: Event, d: Point) => {
        event.stopPropagation();
        handlePointClick(d);
      });

    // Add a circle to each new point
    pointEnter
      .append("circle")
      .attr("r", POINT_SIZE / 2)
      .attr("fill", theme.palette.primary.main);

    // Add text (point ID) to each new point
    pointEnter
      .append("text")
      .attr("text-anchor", "middle")
      .attr("dy", ".3em")
      .attr("fill", "white")
      .attr("font-size", FONT_SIZE);

    // Merge enter and update selections for efficient updates
    const pointUpdate = pointEnter.merge(pointSelection);

    // Update point positions, applying the current zoom transform
    pointUpdate.attr("transform", (d: Point) => {
      const x = currentTransform.applyX(d.x);
      const y = currentTransform.applyY(d.y);
      return `translate(${x}, ${y})`;
    });

    // Update point colors (highlight the start point of a line being drawn)
    pointUpdate
      .select("circle")
      .attr("fill", (d: Point) =>
        d.id === lineStartRef.current?.id
          ? theme.palette.secondary.main
          : theme.palette.primary.main
      );

    // Add text to each point (id is the index here)
    pointUpdate.select("text").text((d: Point) => d.index?.toString() || "");
  };

  useEffect(() => {
    drawPointsAndLines(transform);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allPoints, linesByFloor, lineStart, currentFloor, transform]);

  const handleImageClick = async (event: React.MouseEvent<SVGSVGElement>) => {
    if (disabled || !isAddingPoint || !svgRef.current) return;

    const svg = svgRef.current;

    // Create an SVG point to help with coordinate transformation
    const pt = svg.createSVGPoint();

    // Set the point's coordinates to the click event's client coordinates
    pt.x = event.clientX;
    pt.y = event.clientY;

    // Transform the point from screen coordinates to SVG coordinates
    const svgPoint = pt.matrixTransform(svg.getScreenCTM()?.inverse());

    // Invert the point coordinates based on the current zoom transform
    const invertedPoint = transform.invert([svgPoint.x, svgPoint.y]);

    // Generate the next index for the new point
    const nextIndex = Math.max(...allPoints.map((p) => p.index || 0), 0) + 1;

    const newPoint: Point = {
      x: invertedPoint[0],
      y: invertedPoint[1],
      id: `new-${Date.now()}`,
      floor: currentFloor,
      type: type,
      index: nextIndex,
    };

    try {
      const currentFloorplan = floorplans.find(
        (fp) => fp.floor === currentFloor
      );
      if (currentFloorplan && currentFloorplan.imageId) {
        const { data } = await api.post(
          `customer/image/${currentFloorplan.imageId}/annotation`,
          {
            ...newPoint,
            type,
          }
        );

        // Update the newPoint with the returned data (especially the id)
        const savedPoint = { ...newPoint, id: data.id };

        setAllPoints((prevPoints) => [...prevPoints, savedPoint]);
        drawPointsAndLines(transform, [...allPoints, savedPoint]);
      }
    } catch (error) {
      console.error("Failed to create annotation:", error);
      // Optionally, show an error message to the user
    }
  };

  const handlePointClick = async (point: Point) => {
    if (disabled) return;

    if (!lineStartRef.current) {
      lineStartRef.current = point;
      setLineStart(point);
    } else if (lineStartRef.current.id !== point.id) {
      try {
        const currentFloorplan = floorplans.find(
          (fp) => fp.floor === currentFloor
        );
        if (currentFloorplan && currentFloorplan.imageId) {
          const response = await api.post(
            `customer/image/${currentFloorplan.imageId}/annotation/${lineStartRef.current.id}/link`,
            {
              linkedAnnotationId: point.id,
            }
          );

          if (response.success) {
            const newLink = response.data;
            setLocalAnnotationLinks((prevLinks) => {
              const updatedLinks = [...prevLinks, newLink];
              // Immediately redraw points and lines with the new link
              drawPointsAndLines(transform, allPoints, updatedLinks);
              return updatedLinks;
            });

            // Find existing point for start
            const sourcePoint = allPoints.find(
              (p) => p.id === newLink.sourceAnnotationId
            );

            if (sourcePoint) {
              setLinesByFloor((prevLines) => {
                const updatedLines = {
                  ...prevLines,
                  [currentFloor]: [
                    ...(prevLines[currentFloor] || []),
                    {
                      start: sourcePoint,
                      end: point,
                      floor: currentFloor,
                    },
                  ],
                };
                // Immediately redraw points and lines with the new line
                drawPointsAndLines(
                  transform,
                  allPoints,
                  localAnnotationLinks,
                  updatedLines
                );
                return updatedLines;
              });
            }
          }
        }
      } catch (error) {
        console.error("Failed to create annotation link:", error);
      }

      lineStartRef.current = null;
      setLineStart(null);
    } else {
      lineStartRef.current = null;
      setLineStart(null);
    }
  };

  const handleAddPoint = () => {
    setIsAddingPoint((prev) => !prev);
  };

  const handleUndo = async () => {
    if (disabled) return;

    const currentFloorplan = floorplans.find((fp) => fp.floor === currentFloor);

    // First, check if there are any AnnotationLinks to remove
    const linksToRemove = localAnnotationLinks.filter((link) => {
      const sourcePoint = allPoints.find(
        (p) => p.id === link.sourceAnnotationId
      );
      const targetPoint = allPoints.find(
        (p) => p.id === link.targetAnnotationId
      );
      // If floor is not available, include the link
      return (
        !sourcePoint?.floor ||
        !targetPoint?.floor ||
        sourcePoint.floor === currentFloor ||
        targetPoint.floor === currentFloor
      );
    });

    if (linksToRemove.length > 0) {
      const linkToRemove = linksToRemove[linksToRemove.length - 1];
      try {
        await api.delete(
          `customer/image/${currentFloorplan?.imageId}/annotation/${linkToRemove.sourceAnnotationId}/link/${linkToRemove.id}`
        );
        setLocalAnnotationLinks((prevLinks) =>
          prevLinks.filter((link) => link.id !== linkToRemove.id)
        );

        // Update linesByFloor state
        setLinesByFloor((prevLines) => {
          const updatedLines = { ...prevLines };
          Object.keys(updatedLines).forEach((floor) => {
            updatedLines[floor] = updatedLines[floor].filter(
              (line) =>
                !(
                  line.start.id === linkToRemove.sourceAnnotationId &&
                  line.end.id === linkToRemove.targetAnnotationId
                )
            );
          });
          return updatedLines;
        });

        return; // Exit the function after removing the link
      } catch (error) {
        console.error("Failed to delete annotation link:", error);
      }
    }

    // If no links to remove, proceed with removing points
    const pointsToRemove = allPoints.filter(
      (p) => !p.floor || p.floor === currentFloor
    );
    if (pointsToRemove.length > 0) {
      const pointToRemove = pointsToRemove[pointsToRemove.length - 1];

      try {
        // delete annotation
        await api.delete(
          `customer/image/${currentFloorplan?.imageId}/annotation/${pointToRemove.id}`
        );
        setAllPoints((prevPoints) => {
          const pointsWithoutLast = prevPoints.filter(
            (p) => p.id !== pointToRemove.id
          );
          // Recalculate indices for all points
          const recalculatedPoints = pointsWithoutLast.map((p, index) => ({
            ...p,
            index: index + 1,
          }));

          // Remove lines associated with the removed point
          setLinesByFloor((prevLines) => {
            const updatedLines = { ...prevLines };
            Object.keys(updatedLines).forEach((floor) => {
              updatedLines[floor] = updatedLines[floor].filter(
                (line) =>
                  line.start.id !== pointToRemove.id &&
                  line.end.id !== pointToRemove.id
              );
            });
            return updatedLines;
          });

          // Redraw points immediately
          drawPointsAndLines(transform, recalculatedPoints);
          return recalculatedPoints;
        });
      } catch (error) {
        console.error("Failed to delete annotation:", error);
        // Optionally, show an error message to the user
      }
    }

    // Reset the line start reference and state
    lineStartRef.current = null;
    setLineStart(null);
  };

  const handleImageUpload = async (pointId: string, file: File) => {
    const currentFloorplan = floorplans.find((fp) => fp.floor === currentFloor);
    if (!currentFloorplan || !currentFloorplan.imageId) {
      console.error("No current floorplan found");
      return;
    }

    if (!job) {
      console.error("No job found");
      return;
    }

    try {
      // First, upload the image
      const form = new FormData();
      form.append("title", `Annotation Image for ${pointId}`);
      form.append("type", "annotationImage");
      form.append("jobId", job.id);
      form.append(file.name, file);

      const response = await api.post(
        "customer/image",
        form,
        {},
        { "Content-Type": "multipart/form-data" }
      );

      if (
        response.success &&
        response.data.images &&
        response.data.images.length > 0
      ) {
        const uploadedImage = response.data.images[0];

        // Now, update the annotation with the new image id
        await api.put(
          `customer/image/${currentFloorplan.imageId}/annotation/${pointId}`,
          {
            annotationImageId: uploadedImage.id,
          }
        );

        // Update local state
        setAllPoints((prevPoints) =>
          prevPoints.map((point) =>
            point.id === pointId
              ? { ...point, annotationImageId: uploadedImage.id }
              : point
          )
        );

        const imageResponse = await api.get(
          `customer/image/${uploadedImage.id}`
        );
        if (imageResponse.success && imageResponse.data?.sizes?.default) {
          setPointImages((prev) => ({
            ...prev,
            [pointId]: imageResponse.data?.sizes?.default,
          }));
        }
      } else {
        console.error("Failed to upload image");
      }
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  const formatFloorName = (str: string): string => {
    if (str.toLowerCase() === "basement") return "Basement";
    return `${str.charAt(0).toUpperCase() + str.slice(1)} Floor`;
  };

  const FloorButtons = () => (
    <Box sx={{ mb: 2 }}>
      {floorplans.map((fp) => (
        <Button
          key={fp.floor}
          color="secondary"
          variant="outlined"
          onClick={() => setCurrentFloorAndPlan(fp.floor)}
          sx={{
            mr: 1,
            color:
              currentFloor === fp.floor ? "secondary.main" : "text.secondary",
            borderColor:
              currentFloor === fp.floor ? "secondary.main" : "text.secondary",
          }}
        >
          {formatFloorName(fp.floor || "")}
        </Button>
      ))}
    </Box>
  );

  return (
    <Root>
      <Grid2 container>
        <Grid2 className={classes.floorAndButtonsContainer} size={12}>
          <Typography color="primary" variant="h6">
            {formatFloorName(currentFloor || "")}
          </Typography>
          <FloorButtons />
        </Grid2>
        <Grid2
          size={{
            xs: 12,
            md: disabled ? 12 : 7,
            lg: disabled ? 12 : 8,
          }}
        >
          <Box
            position="relative"
            flexGrow={1}
            overflow="hidden"
            sx={{ height: height || "100%" }}
          >
            <svg
              ref={svgRef}
              className={classes.svg}
              onClick={handleImageClick}
              preserveAspectRatio="xMidYMid meet"
            >
              <defs>
                <symbol id="point" viewBox="0 0 60 60">
                  <circle
                    cx="30"
                    cy="30"
                    r="30"
                    fill={theme.palette.primary.main}
                  />
                </symbol>
              </defs>
              {imageSrc && (
                <image
                  ref={imageRef}
                  href={imageSrc}
                  onError={(e) => {
                    // TODO: handle error gracefully
                    console.error("Error loading image:", e);
                  }}
                />
              )}
              {/* Points and lines will be rendered here */}
              {annotationLinks?.map((link, index) => {
                const sourcePoint = allPoints.find(
                  (p) => p.id === link.sourceAnnotationId
                );
                const targetPoint = allPoints.find(
                  (p) => p.id === link.targetAnnotationId
                );
                if (
                  sourcePoint &&
                  targetPoint &&
                  sourcePoint.floor === currentFloor &&
                  targetPoint.floor === currentFloor
                ) {
                  return (
                    <line
                      key={`link-${index}`}
                      x1={transform.applyX(sourcePoint.x)}
                      y1={transform.applyY(sourcePoint.y)}
                      x2={transform.applyX(targetPoint.x)}
                      y2={transform.applyY(targetPoint.y)}
                      stroke="blue"
                      strokeWidth="2"
                    />
                  );
                }
                return null;
              })}
            </svg>
          </Box>
        </Grid2>
        {!disabled && (
          <Grid2
            size={{
              xs: 12,
              md: 5,
              lg: 4,
            }}
          >
            <CardWithHeader
              sx={{ ml: 2 }}
              title={`${formatFloorName(currentFloor)} ${camelCaseToHuman(type)}`}
              actions={
                <>
                  <Button
                    variant="outlined"
                    onClick={handleAddPoint}
                    className={classes.addPointButton}
                  >
                    {isAddingPoint ? "Stop" : "Add"}
                  </Button>
                  <IconButton
                    onClick={handleUndo}
                    sx={{ color: theme.palette.common.white }}
                  >
                    <UndoIcon />
                  </IconButton>
                </>
              }
            >
              <Box className={classes.pointsByFloorContainer}>
                {allPoints.map((point, index) => (
                  <Box
                    key={`${point.id}-${index}`}
                    className={classes.pointListContainer}
                  >
                    {point.index}
                  </Box>
                ))}
              </Box>
            </CardWithHeader>
          </Grid2>
        )}
        <Grid2 container spacing={2} sx={{ mt: 2 }} size={12}>
          {allPoints.map((point) => (
            <Grid2
              size={{
                xs: 6,
                sm: 4,
                md: 3,
                lg: 2,
              }}
            >
              <ImageUploadWithPlaceholder
                key={point.id}
                disabled={disabled} // passed down from parent
                id={point.id.toString()}
                onImageUpload={handleImageUpload}
                imageUrl={pointImages[point.id.toString()]}
                index={point.index?.toString()}
              />
            </Grid2>
          ))}
        </Grid2>
      </Grid2>
    </Root>
  );
};

export default ImageMarker;
