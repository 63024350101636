/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { WidgetProps } from "@rjsf/utils";
import {
  Typography,
  Grid2,
  Select,
  MenuItem,
  useMediaQuery,
  useTheme,
  Stack,
  FormControl,
  InputLabel,
  Box,
} from "@mui/material";

export const CustomSelectField = (props: WidgetProps) => {
  const theme = useTheme();
  const small = useMediaQuery(theme.breakpoints.down("md"));
  const {
    padding = 0,
    paddingLeft = 0,
    paddingRight = 0,
    left = 0,
    width,
    labelPlacement = "inset",
    variant = "outlined",
    multiple = false,
    max = undefined,
    placeholder = "Select",
    titleKey = "",
    sx = {},
  } = props.uiSchema ?? {};

  const label =
    theme.t(`labels.${titleKey}.${props.name}.label`) ||
    theme.t(`labels.${props.name}.label`) ||
    props.label;
  const labelId = `${props.name}-label`;

  // Common select props and menu items
  const selectProps = {
    variant,
    value: props.value || "",
    onChange: (event: any) => {
      const value = event.target.value;
      props.onChange(multiple ? [value] : value);
    },
    displayEmpty: true,
    renderValue: (selected: any) => {
      if (!selected) {
        return <Typography color="text.secondary">{placeholder}</Typography>;
      }

      // Try to find the option in enumOptions first (if available)
      let selectedOption;
      if (props.options?.enumOptions) {
        selectedOption = props.options.enumOptions.find(
          (option: any) => option.value === selected
        );
        // If found, use its value
        if (selectedOption) {
          selected = selectedOption.value;
        }
      }

      // Return text or fallback to the selected value
      return (
        theme.t(`labels.${props.name}.enum.${selected}.title`) ||
        theme.t(`labels.${props.name}.enum.${selected}`) ||
        selectedOption?.label ||
        selected
      );
    },
    size: "medium" as const,
    multiple: multiple && max !== 1,
  };

  const menuItems = props.options?.enumOptions?.length
    ? props.options.enumOptions.map(
        ({ value, label }: { value: any; label: string }) => (
          <MenuItem key={value} value={value}>
            {label === value
              ? theme.t(`labels.${props.name}.enum.${value}.title`) ||
                theme.t(`labels.${props.name}.enum.${value}`) ||
                value
              : label}
          </MenuItem>
        )
      )
    : props.schema?.items?.enum?.map((value: any) => (
        <MenuItem key={value} value={value}>
          {theme.t(`labels.${props.name}.enum.${value}.title`) ||
            theme.t(`labels.${props.name}.enum.${value}`) ||
            value}
        </MenuItem>
      ));

  // Wrapper component
  const SelectWrapper = ({ children }: { children: React.ReactNode }) => (
    <Stack
      p={1}
      sx={{
        ...sx,
        width: "100%", // Keep the wrapper full width
        display: "flex",
        alignItems: "center", // Center children horizontally
      }}
    >
      <Box sx={{ width: width }}>
        {/* Apply custom width to inner Box */}
        {children}
      </Box>
    </Stack>
  );

  // 'Inline'
  if (labelPlacement === "inline") {
    return (
      <SelectWrapper>
        <Grid2
          container
          justifyContent="center"
          alignItems="center"
          direction="row"
          sx={{
            padding,
            paddingLeft,
            paddingRight,
            left,
            position: "relative",
            width: width || "100%",
            backgroundColor: props.rawErrors?.length
              ? "rgba(255,0,0,0.1)"
              : undefined,
          }}
        >
          <Grid2
            sx={{
              marginBottom: small ? 1 : 0,
              marginTop: small ? 1 : 0,
            }}
            size={{
              xs: 12,
              sm: 12,
              md: 5,
            }}
          >
            <Typography sx={{ fontWeight: 500 }}>{label}</Typography>
          </Grid2>
          <Grid2
            size={{
              xs: 12,
              sm: 12,
              md: 7,
            }}
          >
            <Select
              {...selectProps}
              sx={{
                ml: 1,
                width: "100%",
                left: "-7px",
                p: 1,
                borderRadius: "4px",
                height: 56,
              }}
            >
              {menuItems}
            </Select>
          </Grid2>
        </Grid2>
      </SelectWrapper>
    );
  }

  // 'Inset'
  return (
    <SelectWrapper>
      <FormControl fullWidth size="medium">
        {labelPlacement !== "none" ? (
          <InputLabel id={labelId} shrink>
            {label}
          </InputLabel>
        ) : null}
        <Select
          {...selectProps}
          labelId={labelId}
          id={`${labelId}-select`}
          label={labelPlacement !== "none" ? label : undefined}
          sx={{
            height: 56,
            width: "100%",
            borderRadius: "4px",
          }}
        >
          {menuItems}
        </Select>
      </FormControl>
    </SelectWrapper>
  );
};

export default CustomSelectField;
