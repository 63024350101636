import React, { useContext, useEffect, useState } from "react";
import {
  Alert,
  Box,
  CircularProgress,
  Grid2,
  Snackbar,
  Toolbar,
  useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

import Sherpa from "@library/components/Sherpa";
import { SlideSchema } from "@library/components/Sherpa/functions";
import api from "@library/api";
import { SettingsContext } from "@library/settings/provider";

import { initialOnboardingPayload } from "../../domain/onboarding";
import sortingWorkflowSlides from "./workflows/sorting";
import rebateOnlyWorkflowSlides from "./workflows/rebateOnly";
import instantEstimateWorkflowSlides from "./workflows/instantEstimate";
import OnboardingNavigation from "./components/OnboardingNavigation";

const ONBOARDING_PAYLOAD_VERSION = "1.0.1";

const OnboardingV3PageView = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const storageKey = "onboarding";
  const { user, isAuthenticated, isLoading, channelId } =
    useContext(SettingsContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [resetSlideIndex, setResetSlideIndex] = useState(false);
  const [warning, setWarning] = useState("");

  // @TODO we need to clean all this up
  const homes = user.Home ?? [];
  const home = homes[0];
  const jobs = home?.Job ?? [];
  const job = jobs[0];
  // const quotes = job?.Quote ?? [];
  // const quote = quotes[0];
  const onboarding = job?.Onboarding ?? {};
  const invisible = theme.config?.onboarding?.invisible || [];
  const [onboardingId, setOnboardingId] = useState(
    onboarding?.id || localStorage.getItem("onboardingId")
  );

  let slides = [
    ...sortingWorkflowSlides,
    ...instantEstimateWorkflowSlides,
    ...rebateOnlyWorkflowSlides,
  ] as unknown as SlideSchema[];
  slides = slides.map((s) => {
    const slide = {
      ...s,
    };
    if (invisible.includes(slide.key)) slide.visible = () => false;
    return slide;
  });

  // localStorage
  const localCache = JSON.parse(localStorage.getItem(storageKey) || "{}");
  const localPayload = localCache.payload;
  const localSlideKey =
    localCache.slideKey || theme.config?.onboarding?.default?.slideKey;
  const localSlideIndex =
    slides.findIndex((s) => s.key === localSlideKey) || localCache.slideIndex;

  const _initialPayload = {
    version: ONBOARDING_PAYLOAD_VERSION,
    ...initialOnboardingPayload,
    ...(theme.config?.onboarding?.default?.payload || {}),
    ...localPayload,
  };
  _initialPayload.user.channelId =
    onboarding?.data?.user.channelId &&
    onboarding?.data?.user.channelId !== "pearl"
      ? onboarding?.data?.user.channelId
      : channelId;
  const advisor = job?.Advisor || onboarding.Advisor || {};
  const [initialPayload, setInitialPayload] = useState(_initialPayload);
  const [initialSlideIndex, setInitialSlideIndex] = useState(localSlideIndex);
  const [initialSlideState, setInitialSlideState] = useState({ advisor });

  useEffect(() => {
    if (isAuthenticated) {
      const databaseInitialPayload = {
        ...initialPayload,
        ...(onboarding?.data || {}),
      };
      databaseInitialPayload.user.channelId =
        onboarding?.data?.user.channelId &&
        onboarding?.data?.user.channelId !== "pearl"
          ? onboarding?.data?.user.channelId
          : channelId;
      setInitialPayload(databaseInitialPayload);
      setInitialSlideState({ ...initialSlideState });
      if (onboarding?.id) {
        setOnboardingId(onboarding.id);
        localStorage.setItem("onboardingId", onboarding.id);
      }
      localStorage.setItem(
        "onboarding",
        JSON.stringify(
          {
            payload: databaseInitialPayload,
            slideIndex: onboarding?.step?.activeStep || 0,
            slideKey: onboarding?.step?.activeStepKey || undefined,
          },
          null,
          4
        )
      );
      const version = onboarding?.data?.version;
      if (version !== ONBOARDING_PAYLOAD_VERSION) {
        let slideIndex = 0;
        if (theme.config?.onboarding?.default?.slideKey) {
          slideIndex =
            slides.findIndex(
              (s) => s.key === theme.config.onboarding.default.slideKey
            ) || 0;
        }
        setInitialPayload({
          ...databaseInitialPayload,
          version: ONBOARDING_PAYLOAD_VERSION,
        });
        setInitialSlideState({ ...initialSlideState });
        setResetSlideIndex(true);
        setWarning("We need to verify the questions you previously answered.");
        window.setTimeout(() => {
          setResetSlideIndex(false);
        }, 100);
        setInitialSlideIndex(slideIndex);
      } else if (onboarding?.step?.activeStepKey) {
        const slideIndex = slides.findIndex(
          (s) => s.key === onboarding.step.activeStepKey
        );
        setInitialSlideIndex(slideIndex);
      }
    } else if (
      (onboardingId || localStorage.getItem("onboardingId")) &&
      !isLoading &&
      !isAuthenticated
    ) {
      api
        .get(
          `customer/onboarding/partial/${onboardingId || localStorage.getItem("onboardingId")}`
        )
        .then((response) => {
          const version = response?.data?.data?.version;
          setInitialPayload({
            ...initialPayload,
            ...(response?.data?.data || {}),
          });
          setInitialSlideState({
            ...initialSlideState,
            advisor: response?.data?.Advisor || initialSlideState.advisor || {},
          });
          if (version !== ONBOARDING_PAYLOAD_VERSION) {
            let slideIndex = 0;
            if (theme.config?.onboarding?.default?.slideKey) {
              slideIndex =
                slides.findIndex(
                  (s) => s.key === theme.config.onboarding.default.slideKey
                ) || 0;
            }
            setInitialPayload({
              ...initialPayload,
              ...(response?.data?.data || {}),
              version: ONBOARDING_PAYLOAD_VERSION,
            });
            setInitialSlideState({
              ...initialSlideState,
              advisor:
                response?.data?.Advisor || initialSlideState.advisor || {},
            });
            setResetSlideIndex(true);
            setWarning(
              "We need to verify the questions you previously answered."
            );
            window.setTimeout(() => {
              setResetSlideIndex(false);
            }, 100);
            setInitialSlideIndex(slideIndex);
          } else if (response?.data?.step?.activeStepKey) {
            const slideIndex = slides.findIndex(
              (s) => s.key === response.data.step.activeStepKey
            );
            setInitialSlideIndex(slideIndex);
          }
        });
    } else if (
      !onboardingId &&
      !localStorage.getItem("onboardingId") &&
      !isLoading &&
      !isAuthenticated
    ) {
      if (
        localPayload &&
        Object.keys(localPayload).length &&
        localPayload?.version !== ONBOARDING_PAYLOAD_VERSION
      ) {
        let slideIndex = 0;
        if (theme.config?.onboarding?.default?.slideKey) {
          slideIndex =
            slides.findIndex(
              (s) => s.key === theme.config.onboarding.default.slideKey
            ) || 0;
        }
        setInitialPayload({
          ...initialPayload,
          version: ONBOARDING_PAYLOAD_VERSION,
        });
        setInitialSlideState({ ...initialSlideState });
        setInitialSlideIndex(slideIndex);
        setWarning("We need to verify the questions you previously answered.");
        setResetSlideIndex(true);
        window.setTimeout(() => {
          setResetSlideIndex(false);
        }, 100);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onboardingStatus = onboarding?.status || "";

  useEffect(() => {
    if (onboarding?.status === "COMPLETED") {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onboardingStatus]);

  return (
    <>
      {isSubmitting ? (
        <Box component={"main"}>
          <Toolbar />{" "}
          <Grid2
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{ width: "100%", minHeight: "calc(100vh - 128px)" }}
          >
            <Grid2>
              <CircularProgress />
            </Grid2>
          </Grid2>
        </Box>
      ) : (
        <Sherpa
          slides={slides}
          data={{}}
          initialSlideIndex={initialSlideIndex > -1 ? initialSlideIndex : 0}
          resetSlideIndex={resetSlideIndex}
          initialPayload={initialPayload}
          initialSlideState={initialSlideState}
          Navigation={OnboardingNavigation}
          navigationPlacement="footer"
          onProgress={({ payload, slideIndex, slideKey }) => {
            if (
              !isAuthenticated &&
              (onboardingId || localStorage.getItem("onboardingId"))
            ) {
              return api.put(
                `customer/onboarding/partial/${onboardingId || localStorage.getItem("onboardingId")}`,
                {
                  payload,
                  slideIndex,
                  slideKey,
                }
              );
            }
            if (
              isAuthenticated &&
              (onboardingId || localStorage.getItem("onboardingId"))
            ) {
              return api.put(
                `customer/onboarding/${onboardingId || localStorage.getItem("onboardingId")}`,
                {
                  payload,
                  activeStep: slideIndex,
                  activeStepKey: slideKey,
                }
              );
            }
            // @TODO route to save a claimed onboarding
            return Promise.resolve();
          }}
          onSubmit={async ({ payload, slideIndex, slideKey, slide }) => {
            if (slide.noSubmit) return false;
            if (
              isAuthenticated &&
              (onboardingId || localStorage.getItem("onboardingId"))
            ) {
              setIsSubmitting(true);
              try {
                await api.put(
                  `customer/onboarding/${onboardingId || localStorage.getItem("onboardingId")}`,
                  {
                    payload,
                    activeStep: slideIndex,
                    activeStepKey: slideKey,
                  }
                );
                const response = await api.post(
                  `customer/onboarding/complete/${onboardingId || localStorage.getItem("onboardingId")}`,
                  {
                    tiers: theme.config?.tiers?.available ?? ["base", "pearl"],
                  }
                );
                const { data: { jobId } = {} } = response;
                localStorage.removeItem("onboardingId");
                localStorage.removeItem("onboarding");
                window.location.href = `/job/${jobId}`;
              } catch (e) {
                setIsSubmitting(false);
                // @TODO set error
              }
            }
          }}
          storageKey={storageKey}
        />
      )}
      <Snackbar
        open={warning?.length > 0}
        color="warning"
        autoHideDuration={7000}
        onClose={() => {
          setWarning("");
        }}
        message={warning}
      >
        <Alert
          onClose={() => {
            setWarning("");
          }}
          severity="warning"
          variant="filled"
          sx={{ width: "100%", color: theme.palette.common.white }}
        >
          {warning}
        </Alert>
      </Snackbar>
    </>
  );
};

export default OnboardingV3PageView;
