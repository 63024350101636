import React, { useRef, useState, useEffect } from "react";
import { UploadFile, Delete, CheckCircle } from "@mui/icons-material";
import {
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Dialog,
  DialogContent,
  Stack,
  Button,
} from "@mui/material";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { Root, classes } from "./styles";

import { pdfjs, Document, Page } from "react-pdf";
import workerSrc from "pdfjs-dist/build/pdf.worker.min.mjs?url";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";

pdfjs.GlobalWorkerOptions.workerSrc = workerSrc;

interface FileRowProps {
  uniqueId?: string;
  fileName: string;
  fileSize: string;
  floor?: string;
  onFloorChange?: (floor: string) => void;
  onReupload: (file: File) => void;
  onDelete: () => void;
  floorOptions?: string[];
  fileUrl?: string;
  allowReupload?: boolean;
  mimetype?: string;
}

const FileRow: React.FC<FileRowProps> = ({
  fileName,
  fileSize,
  floor = "first",
  onFloorChange,
  onReupload,
  allowReupload = true,
  onDelete,
  floorOptions = ["basement", "first", "second", "third"],
  uniqueId,
  fileUrl,
  mimetype = "image/png",
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [thumbnailUrl, setThumbnailUrl] = useState<string | null>(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const [numPages, setNumPages] = useState<number>();
  const [pageNumber, setPageNumber] = useState<number>(1);

  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setNumPages(numPages);
  }

  const handleReuploadClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const selectId = `floor-select-${uniqueId}`;
  const fileInputId = `file-input-${uniqueId}`;

  useEffect(() => {
    if (fileUrl) {
      setThumbnailUrl(fileUrl);
    }
  }, [fileUrl]);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      onReupload(file);

      const reader = new FileReader();
      reader.onload = (event) => {
        setThumbnailUrl(event.target?.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleThumbnailClick = () => {
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };
  return (
    <Root>
      <Box className={classes.fileRowOuterContainer}>
        <Box className={classes.fileNameContainer}>
          {thumbnailUrl && mimetype !== "application/pdf" ? (
            <img
              src={thumbnailUrl}
              alt="Thumbnail"
              className={classes.thumbnail}
              onClick={handleThumbnailClick}
            />
          ) : thumbnailUrl && mimetype === "application/pdf" ? (
            <Stack mr={1}>
              <PictureAsPdfIcon
                sx={{ cursor: "pointer" }}
                onClick={handleThumbnailClick}
              />
            </Stack>
          ) : (
            <UploadFile sx={{ mr: 2 }} />
          )}
          <Box>
            <Typography>{fileName}</Typography>
            <Typography variant="body2">{fileSize} Complete</Typography>
          </Box>
        </Box>
        <Box className={classes.floorSelectionContainer}>
          {onFloorChange && (
            <FormControl fullWidth className={classes.floorSelectFormControl}>
              <InputLabel id={`${selectId}-label`}>Floor</InputLabel>
              <Select
                labelId={`${selectId}-label`}
                id={selectId}
                label="Floor"
                value={floor}
                onChange={(e) => onFloorChange(e.target.value as string)}
              >
                {floorOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option.charAt(0).toUpperCase() + option.slice(1)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          <Box className={classes.iconContainer}>
            <input
              type="file"
              id={fileInputId}
              name={fileName}
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
            {allowReupload !== false ? (
              <label htmlFor={fileInputId}>
                <IconButton
                  component="span"
                  onClick={handleReuploadClick}
                  sx={{ mr: 1 }}
                >
                  <UploadFile />
                </IconButton>
              </label>
            ) : null}
            <IconButton onClick={onDelete} sx={{ mr: 1 }}>
              <Delete />
            </IconButton>
            <CheckCircle color="success" />
          </Box>
        </Box>
      </Box>
      <Dialog
        open={isDialogOpen}
        onClose={handleCloseDialog}
        maxWidth="md"
        fullWidth
      >
        <DialogContent>
          {thumbnailUrl && mimetype !== "application/pdf" ? (
            <img
              src={thumbnailUrl}
              alt={fileName}
              style={{ width: "100%", height: "auto" }}
            />
          ) : thumbnailUrl && mimetype === "application/pdf" ? (
            <Stack
              sx={{
                canvas: { width: "100% !important", height: "auto !important" },
              }}
            >
              <Stack direction="row" justifyContent="space-between">
                <Button
                  onClick={() =>
                    setPageNumber(pageNumber - 1 > 0 ? pageNumber - 1 : 0)
                  }
                >
                  Previous
                </Button>
                <Stack direction="row">
                  <Typography>
                    {pageNumber} / {numPages}
                  </Typography>
                </Stack>
                <Button
                  onClick={() =>
                    setPageNumber(
                      pageNumber + 1 <= (numPages || 0)
                        ? pageNumber + 1
                        : numPages || 0
                    )
                  }
                >
                  Next
                </Button>
              </Stack>
              <Document
                file={thumbnailUrl}
                onLoadSuccess={onDocumentLoadSuccess}
              >
                <Page pageNumber={pageNumber} width={700} />
              </Document>
            </Stack>
          ) : null}
        </DialogContent>
      </Dialog>
    </Root>
  );
};

export default FileRow;
