import axios, { AxiosResponse } from "axios";
import isString from "lodash/isString";
import isObject from "lodash/isObject";

export class ForbiddenError extends Error {}

interface Params {
  [key: string]: string | number | null | undefined;
}

interface Data {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

interface APIRequest {
  endpoint: string;
  data?: Data;
  params?: Params;
  headers?: Headers;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cast?: (data: any) => any;
}

interface Headers {
  [key: string]: string;
}

const methods = {
  get: "",
  put: "",
  post: "",
  delete: "",
};

export default class API {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  signal: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  controllers: any;
  constructor() {
    this.controllers = {};
  }

  formatQuery(endpoint: string, params: Params) {
    let path = `${import.meta.env.VITE_NODE_API_URL}/${endpoint}`;
    if (params.id) {
      path += `/${params.id}`;
    }
    delete params.id;
    const uri = Object.keys(params).reduce(
      (a, v) => `${a}${a === "" ? "?" : "&"}${v}=${params[v]}`,
      ""
    );
    return `${path}/${uri}`;
  }

  async get(_e: string | APIRequest, _params?: Params, _headers?: Headers) {
    const endpoint = isString(_e) ? _e : _e.endpoint;
    const params = (isString(_e) ? _params : _e.params) || {};
    const __headers = (isString(_e) ? _headers : _e.headers) || {};
    const headers = {
      "Content-Type": "application/json",
      "X-Pearl-Edison": "true",
      ...(__headers || {}),
    } as Headers;
    const pearlToken = localStorage.getItem("pearlToken");
    const supabaseToken = localStorage.getItem(
      `sb-${import.meta.env.VITE_SUPABASE_PROJECT_ID}-auth-token`
    );
    if (pearlToken) {
      headers.Authorization = `Bearer ${pearlToken}`;
    } else if (supabaseToken) {
      try {
        const token = JSON.parse(supabaseToken);
        headers.Authorization = `Bearer ${token.access_token}`;
      } catch (e) {
        // do nothing
      }
    }
    const res = (await axios.get(this.formatQuery(endpoint, params), {
      withCredentials: true,
      headers,
    })) as AxiosResponse;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const cast = isObject(_e) && _e.cast ? _e.cast : (resp: any) => resp;
    return cast(res.data);
  }

  async mutate(
    method: string,
    endpoint: string,
    data: Data = {},
    params: Params = {},
    _headers: Headers = {},
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    signal?: any
  ) {
    const headers = {
      "Content-Type": "application/json",
      "X-Pearl-Edison": "true",
      ..._headers,
    } as Headers;
    const pearlToken = localStorage.getItem("pearlToken");
    const supabaseToken = localStorage.getItem(
      `sb-${import.meta.env.VITE_SUPABASE_PROJECT_ID}-auth-token`
    );
    if (pearlToken) {
      headers.Authorization = `Bearer ${pearlToken}`;
    } else if (supabaseToken) {
      try {
        const token = JSON.parse(supabaseToken);
        headers.Authorization = `Bearer ${token.access_token}`;
      } catch (e) {
        // do nothing
      }
    }

    const res = (await axios[method as keyof typeof methods](
      this.formatQuery(endpoint, params),
      method === "delete" ? { headers, signal: this.signal } : data,
      {
        withCredentials: true,
        headers,
        signal,
      }
    )) as AxiosResponse;

    return res.data;
  }

  async put(
    _e: string | APIRequest,
    _data?: Data,
    _params?: Params,
    _headers?: Headers
  ) {
    const endpoint = isString(_e) ? _e : _e.endpoint;
    const data = isString(_e) ? _data : _e.data || {};
    const params = isString(_e) ? _params : _e.params;
    const headers = isString(_e) ? _headers : _e.headers;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const cast = isObject(_e) && _e.cast ? _e.cast : (resp: any) => resp;
    const resp = await this.mutate("put", endpoint, data, params, headers);
    return cast(resp);
  }

  async post(
    _e: string | APIRequest,
    _data?: Data,
    _params?: Params,
    _headers?: Headers
  ) {
    const endpoint = isString(_e) ? _e : _e.endpoint;
    const data = isString(_e) ? _data : _e.data;
    const params = isString(_e) ? _params : _e.params;
    const headers = isString(_e) ? _headers : _e.headers;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const cast = isObject(_e) && _e.cast ? _e.cast : (resp: any) => resp;

    // @TODO Temporary fix to kill "in-flight" engine requests if the user is going through onboarding pretty quick
    let _signal;
    if (endpoint === "customer/onboarding/engine") {
      if (this.controllers[endpoint]?.post?.controller?.abort) {
        this.controllers[endpoint].post.controller.abort();
      }
      const controller = new AbortController();
      const { signal } = controller;
      if (!this.controllers[endpoint]) this.controllers[endpoint] = {};
      this.controllers[endpoint].post = {
        controller,
        signal,
      };
      _signal = signal;
    }
    const resp = await this.mutate(
      "post",
      endpoint,
      data,
      params,
      headers,
      _signal
    );
    return cast(resp);
  }

  async delete(_e: string | APIRequest, _params?: Params, _headers?: Headers) {
    const endpoint = isString(_e) ? _e : _e.endpoint;
    const params = isString(_e) ? _params : _e.params;
    const headers = isString(_e) ? _headers : _e.headers;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const cast = isObject(_e) && _e.cast ? _e.cast : (resp: any) => resp;
    const resp = await this.mutate("delete", endpoint, {}, params, headers);
    return cast(resp);
  }
}
