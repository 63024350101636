import api from "@library/api";
import { EMAIL, PHONE_NUMBER, stringToBoolean } from "@library/common";
import {
  Alert,
  Box,
  Button,
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material";
import React, { Dispatch, SetStateAction, useState } from "react";
import { useParams } from "react-router-dom";

export const UnsubscribeView = () => {
  const theme = useTheme();
  const { userId } = useParams();
  const [saved, setSaved] = useState(false);
  const [error, setError] = useState(false);
  const [emailTransactional, setEmailTransactional] = useState(false);
  const [smsTransactional, setSmsTransactional] = useState(false);
  const [emailReminders, setEmailReminders] = useState(false);
  const [smsReminders, setSmsReminders] = useState(false);
  const [emailMarketing, setEmailMarketing] = useState(false);
  const [smsMarketing, setSmsMarketing] = useState(false);
  const [emailAll, setEmailAll] = useState(false);
  const [smsAll, setSmsAll] = useState(false);

  const methods = [
    {
      title: "Transactional",
      description: "Automatic alerts for account updates",
      emailValue: emailTransactional,
      emailFn: setEmailTransactional,
      smsValue: smsTransactional,
      smsFn: setSmsTransactional,
    },
    {
      title: "Reminders",
      description: "Automatic alerts to take action, such as completing a task",
      emailValue: emailReminders,
      emailFn: setEmailReminders,
      smsValue: smsReminders,
      smsFn: setSmsReminders,
    },
    {
      title: "Marketing",
      description: "Promos for our products and services",
      emailValue: emailMarketing,
      emailFn: setEmailMarketing,
      smsValue: smsMarketing,
      smsFn: setSmsMarketing,
    },
    {
      title: "All",
      description: "Unsubscribe from all Pearl Edison communications",
      emailValue: emailAll,
      emailFn: setEmailAll,
      smsValue: smsAll,
      smsFn: setSmsAll,
    },
  ];

  const savePreferences = async () => {
    try {
      await api.put(`customer/notifications/${userId}`, {
        emailTransactional,
        emailReminders,
        emailMarketing,
        emailAll,
        smsTransactional,
        smsReminders,
        smsMarketing,
        smsAll,
      });
      setError(false);
      setSaved(true);
    } catch (e) {
      setSaved(false);
      setError(true);
    }
  };

  return (
    <Box component={"main"}>
      <Toolbar />{" "}
      <Stack
        spacing={0}
        alignItems="center"
        justifyContent="center"
        sx={{ minHeight: "calc(100vh - 200px)" }}
      >
        {!userId ? (
          <Alert severity="error">
            We are unable to complete this request. Please contact us via the
            chat widget below, by email at {EMAIL} or by phone at {PHONE_NUMBER}
            .
          </Alert>
        ) : (
          <Stack justifyContent="center" pb={5}>
            <Stack justifyContent="center" direction="row">
              <Typography variant="h1" sx={{ mb: 2 }}>
                Unsubscribe
              </Typography>
            </Stack>
            <Stack
              alignItems="center"
              justifyContent="center"
              sx={{
                backgroundColor: theme.palette.common.white,
                p: 3,
                borderRadius: 1,
              }}
            >
              {/* Transactional */}
              {methods.map(
                (m: {
                  title: string;
                  description: string;
                  emailValue: boolean;
                  emailFn: Dispatch<SetStateAction<boolean>>;
                  smsValue: boolean;
                  smsFn: Dispatch<SetStateAction<boolean>>;
                }) => {
                  return (
                    <>
                      <Stack
                        justifyContent="flex-start"
                        direction="row"
                        alignItems="center"
                        sx={{ mt: 2, width: "100%" }}
                      >
                        <Stack sx={{ width: "100%" }}>
                          <Typography
                            sx={{ fontWeight: "700", fontSize: "1.2rem" }}
                          >
                            {m.title}
                          </Typography>
                          <Typography
                            sx={{ fontWeight: "400", fontSize: "0.8rem" }}
                          >
                            {m.description}
                          </Typography>
                          <Divider sx={{ width: "100%", mt: 1 }} />
                          <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <Stack width="200px">
                              <Typography
                                sx={{ fontWeight: "500", fontSize: "1.2rem" }}
                              >
                                Email
                              </Typography>
                            </Stack>
                            <Stack>
                              <RadioGroup
                                value={m.emailValue}
                                onChange={(event) => {
                                  m.emailFn(
                                    stringToBoolean(event?.target.value)
                                  );
                                }}
                              >
                                <Stack direction="row" spacing={3}>
                                  <FormControlLabel
                                    value={true}
                                    control={
                                      <Radio
                                        sx={{
                                          "&.Mui-checked": {
                                            color: theme.palette.primary.main,
                                          },
                                        }}
                                      />
                                    }
                                    label="Yes"
                                  />
                                  <FormControlLabel
                                    value={false}
                                    control={
                                      <Radio
                                        sx={{
                                          "&.Mui-checked": {
                                            color: theme.palette.primary.main,
                                          },
                                        }}
                                      />
                                    }
                                    label="No"
                                  />
                                </Stack>
                              </RadioGroup>
                            </Stack>
                          </Stack>
                          <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <Stack width="200px">
                              <Typography
                                sx={{ fontWeight: "500", fontSize: "1.2rem" }}
                              >
                                SMS
                              </Typography>
                            </Stack>
                            <Stack>
                              <RadioGroup
                                value={m.smsValue}
                                onChange={(event) => {
                                  m.smsFn(stringToBoolean(event?.target.value));
                                }}
                              >
                                <Stack direction="row" spacing={3}>
                                  <FormControlLabel
                                    value={true}
                                    control={
                                      <Radio
                                        sx={{
                                          "&.Mui-checked": {
                                            color: theme.palette.primary.main,
                                          },
                                        }}
                                      />
                                    }
                                    label="Yes"
                                  />
                                  <FormControlLabel
                                    value={false}
                                    control={
                                      <Radio
                                        sx={{
                                          "&.Mui-checked": {
                                            color: theme.palette.primary.main,
                                          },
                                        }}
                                      />
                                    }
                                    label="No"
                                  />
                                </Stack>
                              </RadioGroup>
                            </Stack>
                          </Stack>
                        </Stack>
                      </Stack>
                    </>
                  );
                }
              )}
              {saved ? (
                <Alert severity="success" sx={{ mt: 2 }}>
                  Your preferences have been saved
                </Alert>
              ) : error ? (
                <Alert severity="error" sx={{ mt: 2 }}>
                  We were unable to save your preferences
                </Alert>
              ) : null}
              <Button
                variant="contained"
                onClick={savePreferences}
                sx={{ mt: 3 }}
              >
                Save Preferences
              </Button>
            </Stack>
          </Stack>
        )}
      </Stack>
    </Box>
  );
};

export default UnsubscribeView;
